// src/api/mockApi.js

export const fetchModels = async () => {
    return new Promise((resolve) => {
        setTimeout(() => {
            resolve([
                { id: 1, name: 'GPT-3' },
                { id: 2, name: 'GPT-3.5' },
                { id: 3, name: 'GPT-4' },
                { id: 4, name: 'GPT-4 Turbo' }
            ]);
        }, 1000);
    });
};
