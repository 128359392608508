// DropdownItemConfig.js
import { DropdownItem } from "reactstrap";

const DropdownItemConfig = ({ onClick, className, icon: Icon, label }) => (
  <DropdownItem onClick={onClick} className={className}>
    <Icon className="me-2" />
    {label}
  </DropdownItem>
);

export default DropdownItemConfig;
