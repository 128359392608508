// src/index.js

import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './global.css';
import {createRoot} from 'react-dom/client';
import {BrowserRouter as Router} from 'react-router-dom';
import {PublicClientApplication} from '@azure/msal-browser';
import {MsalProvider} from '@azure/msal-react';
import {msalConfig} from './auth/authConfig';
import './i18n'; // Importer i18n-konfigurasjonen
import App from './App';
import {ComponentPreviews, useInitial} from "./dev";
import {DevSupport} from "@react-buddy/ide-toolbox";

const msalInstance = new PublicClientApplication(msalConfig);

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
    <React.StrictMode>
        <MsalProvider instance={msalInstance}>
            <Router>
                <DevSupport ComponentPreviews={ComponentPreviews}
                            useInitialHook={useInitial}
                >
                    <App/>
                </DevSupport>
            </Router>
        </MsalProvider>
    </React.StrictMode>
);
