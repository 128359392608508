import React, { useState } from "react";
import { Button, Alert } from "reactstrap";
import { useTranslation } from "react-i18next";
import { FaTimes } from "react-icons/fa";
import GenericList from "../genericList/GenericList";
import LoadingOverlay from "../loadingOverlay/LoadingOverlay";
import "./ClassificationInfo.css";

const ClassificationInfo = ({
                                isLoading,
                                classificationData,
                                selectedFile,
                                onClose,
                            }) => {
    const { t } = useTranslation();
    const [expandedRow, setExpandedRow] = useState(null);
    const [searchTerm, setSearchTerm] = useState("");

    const handleLoadingComplete = () => {
        console.log("Loading complete");
    };

    if (isLoading) {
        return <LoadingOverlay isLoading={isLoading} onLoadingComplete={handleLoadingComplete} />;
    }

    // Hvis ingen data er tilgjengelig
    if (!classificationData || Object.keys(classificationData).length === 0) {
        return null;
    }

    // Hvis det er en generell feil i dataene
    if (classificationData?.error) {
        const errorDetails = classificationData.error;
        return (
            <div className="classification-info">
                <Alert color="danger" className="text-center">
                    {t("An error occurred during processing. Please try again later.")}
                </Alert>
                <div className="error-details">
                    <h5>{t("Error Details")}</h5>
                    {errorDetails.status && (
                        <p>
                            <strong>{t("Status Code")}:</strong> {errorDetails.status}
                        </p>
                    )}
                    {errorDetails.message && (
                        <p>
                            <strong>{t("Message")}:</strong> {errorDetails.message}
                        </p>
                    )}
                    {errorDetails.details && (
                        <p>
                            <strong>{t("Details")}:</strong> {errorDetails.details}
                        </p>
                    )}
                </div>
            </div>
        );
    }

    const isDataCorrupt =
        !classificationData?.fields || !classificationData?.confidenceScores;

    if (isDataCorrupt) {
        return (
            <div className="classification-info">
                <Alert color="danger" className="text-center">
                    {t("The classification data is incomplete or corrupt.")}
                </Alert>
                <div className="raw-response">
                    <h5>{t("Raw Response")}</h5>
                    <pre>{JSON.stringify(classificationData, null, 2)}</pre>
                </div>
            </div>
        );
    }

    // Bygg og sorter data hvis den er gyldig
    const combinedData = Object.keys(classificationData.fields)
        .map((key) => ({
            category: key,
            description: classificationData.fields[key] || t("No description available"),
            confidenceScore: (classificationData.confidenceScores?.[key] || 0) * 100,
            explanations: classificationData.explanations?.[key] || t("No explanation available"),
        }))
        .sort((a, b) => b.confidenceScore - a.confidenceScore); // Sorter etter høyeste score

    const filteredData = combinedData.filter(
        (item) =>
            item.category.toLowerCase().includes(searchTerm.toLowerCase()) ||
            item.description.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <div className="classification-info">
            <div className="d-flex justify-content-between align-items-center mb-3">
                <h4 className="classification-title">{t("Classification Information")}</h4>
                <Button color="danger" size="sm" onClick={onClose}>
                    <FaTimes /> {t("Close")}
                </Button>
            </div>
            {selectedFile && (
                <p className="selected-file">
                    <strong>{t("Selected File")}:</strong> {selectedFile}
                </p>
            )}
            <GenericList
                loading={false}
                data={filteredData}
                columns={[
                    { key: "category", label: t("Category") },
                    { key: "description", label: t("Description") },
                    {
                        key: "confidenceScore",
                        label: t("Confidence Score"),
                        render: (item) => `${item.confidenceScore.toFixed(2)}%`,
                    },
                ]}
                searchTerm={searchTerm}
                setSearchTerm={setSearchTerm}
                expandedRow={expandedRow}
                onRowClick={(item) => setExpandedRow((prev) => (prev?.category === item.category ? null : item))}
                toggleExpandedRow={(item) => (
                    <>
                        <p>{item.explanations || <span className="unknown-value">{t("No explanations provided")}</span>}</p>
                    </>
                )}
                rowEqualityCheck={(row1, row2) => row1?.category === row2?.category}
                noResultsMessage={t("No classification data available")}
            />
        </div>
    );
};

export default ClassificationInfo;
