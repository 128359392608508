import React, { useState, useEffect, useCallback } from "react";
import { Container, Row, Col } from "reactstrap";
import { FaPlus, FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { useParams, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useContent from "../hooks/useContent";
import ContentForm from "../components/content/ContentForm";
import ContentList from "../components/content/ContentList";
import ContentInfo from "../components/content/ContentInfo";
import PrimaryButton from "../components/buttons/Button";
import "./Content.css";

const Content = () => {
  const { contentId } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { content, loading, selectedContent, setSelectedContent, getContent } = useContent(contentId);

  const [showAddForm, setShowAddForm] = useState(false);
  const [showEditForm, setShowEditForm] = useState(false);
  const [isContentListVisible, setIsContentListVisible] = useState(true);

  useEffect(() => {
    if (contentId && content.length > 0) {
      const selected = content.find((item) => item.id === contentId);
      if (selected && selected.id !== selectedContent?.id) {
        setSelectedContent(selected);
      }
    } else if (!contentId) {
      setSelectedContent(null);
    }
  }, [contentId, content, setSelectedContent, selectedContent]);

  useEffect(() => {
    if (selectedContent && selectedContent.id !== contentId) {
      navigate(`/content/${selectedContent.id}`, { replace: true });
    }
  }, [selectedContent, navigate, contentId]);


 const handleContentSelect = useCallback(
  (item) => {
    if (item.id !== contentId) {
      setSelectedContent(item);
      navigate(`/content/${item.id}`, { replace: true });

      if (window.innerWidth <= 768) {
        setIsContentListVisible(false); 
      }
    }
  },
  [contentId, setSelectedContent, navigate]
);


  const handleAddNewContent = () => {
    setSelectedContent(null);
    navigate(`/content`);
    setShowAddForm(true);
    setShowEditForm(false);
  };

  const handleEditContent = () => {
    setShowEditForm(true);
    setShowAddForm(false);
  };

  const handleSuccess = useCallback(
    (newContent) => {
      setSelectedContent(newContent);
      setShowAddForm(false);
      setShowEditForm(false);
      getContent();
  
      if (newContent && newContent.id) {
        navigate(`/content/${newContent.id}`, { replace: true });
      } else {
        navigate(`/content/${contentId}`, { replace: true });
      }
    },
    [getContent, setSelectedContent, navigate, contentId]
  );

  const handleCancel = () => {
    setShowAddForm(false);
    setShowEditForm(false);
  };

  const toggleContentList = () => {
    setIsContentListVisible((prev) => !prev);
  };

  return (
    <Container fluid className="content-container min-vh-100">
      <button className="toggle-button" onClick={toggleContentList}>
        {isContentListVisible ? <FaChevronLeft /> : <FaChevronRight />}
      </button>

      <Row className="h-100">
        {isContentListVisible && (
          <Col
            md="4"
            xl="3"
            className={`d-flex flex-column contentlist-slide ${isContentListVisible ? "" : "contentlist-slide-hidden"}`}
          >
            <ContentList
              content={content}
              onSelectContent={handleContentSelect}
              loading={loading}
              selectedContent={selectedContent} // Pass selectedContent as a prop
            />
          </Col>
        )}
        <Col md={isContentListVisible ? "8" : "12"} xl={isContentListVisible ? "9" : "12"} className="d-flex flex-column">
          <Row className="add-button-container">
            <div className="text-end">
              <PrimaryButton label={t("add_new_content")} icon={FaPlus} onClick={handleAddNewContent} />
            </div>
          </Row>
          {selectedContent && !showEditForm ? (
            <ContentInfo onSuccess={handleSuccess} content={selectedContent} onEdit={handleEditContent} />
          ) : showAddForm ? (
            <ContentForm content={null} onSuccess={handleSuccess} onCancel={handleCancel} />
          ) : showEditForm ? (
            <ContentForm content={selectedContent} onSuccess={handleSuccess} onCancel={handleCancel} />
          ) : (
            <div className="empty-state">
              <h4>{t("select_or_add_content")}</h4>
            </div>
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default Content;
