import React, { useReducer, useEffect } from "react";
import {
  Card,
  CardBody,
  CardTitle,
  Container,
  Row,
  Col,
  CardText,
} from "reactstrap";
import { FaRegFileAlt, FaAlignLeft, FaTag, FaLink, FaEdit, FaPlus, FaTags } from "react-icons/fa";
import "./ContentInfo.css";
import { useTheme } from "../../theme/theme";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import FeedbackModal from "../feedbackModal/FeedbackModal";
import { DeleteModal } from "../deleteModal/DeleteModal";
import { DropdownActions } from "../dropdownAction/DropdownAction";
import useContent from "../../hooks/useContent";
import useClassify from "../../hooks/useClassify";
import ClassificationInfo from "../classification/ClassificationInfo";
import ClassificationSelector from "../classification/ClassificationSelector";

const initialState = {
  isLoading: false,
  statusMessage: "",
  classificationData: null,
  selectedFile: "",
  feedbackMessage: "",
  feedbackModalOpen: false,
  feedbackColor: "success",
  showClassificationInfo: false,
  classifyModalOpen: false,
  modalOpen: false,
  showCopyAlert: false,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "START":
      return { ...state, isLoading: true, selectedFile: action.payload, statusMessage: "Processing started...", showClassificationInfo: true };
    case "SET_MESSAGE":
      return { ...state, statusMessage: action.payload };
    case "SET_DATA":
      return { ...state, classificationData: action.payload, isLoading: false, statusMessage: "Processing complete!" };
    case "RESET":
      return initialState;
    case "RESET_CLASSIFICATION_INFO":
      return { ...state, classificationData: null, selectedFile: "", showClassificationInfo: false };
    case "TOGGLE_MODAL":
      return { ...state, modalOpen: !state.modalOpen };
    case "TOGGLE_CLASSIFY_MODAL":
      return { ...state, classifyModalOpen: !state.classifyModalOpen, showClassificationInfo: true };
    case "SET_FEEDBACK":
      return { ...state, feedbackMessage: action.payload.message, feedbackColor: action.payload.color, feedbackModalOpen: true };
    case "CLOSE_FEEDBACK":
      return { ...state, feedbackModalOpen: false };
    case "SHOW_COPY_ALERT":
      return { ...state, showCopyAlert: true };
    case "HIDE_COPY_ALERT":
      return { ...state, showCopyAlert: false };
    case "ERROR":
      return {
        ...state,
        isLoading: false,
        classificationData: { error: action.payload },
        statusMessage: "An error occurred.",
      };
    default:
      return state;
  }
};


const ContentInfo = ({ content, onEdit, onSuccess }) => {
  const { t } = useTranslation();
  const [state, dispatch] = useReducer(reducer, initialState);
  const { classifyCategoryData } = useClassify();
  const { deleteContentItem } = useContent();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch({ type: "RESET_CLASSIFICATION_INFO" });
  }, [content]);

  useEffect(() => {
    if (state.feedbackModalOpen) {
      const timer = setTimeout(() => dispatch({ type: "CLOSE_FEEDBACK" }), 3000);
      return () => clearTimeout(timer);
    }
  }, [state.feedbackModalOpen]);

  useEffect(() => {
    if (state.elapsedTime >= 10 && state.elapsedTime < 60) {
      dispatch({ type: "SET_MESSAGE", payload: "Processing is taking longer than expected..." });
    } else if (state.elapsedTime >= 60 && state.elapsedTime < 120) {
      dispatch({ type: "SET_MESSAGE", payload: "This might take up to 2 minutes. Thank you for your patience." });
    } else if (state.elapsedTime >= 120) {
      dispatch({ type: "SET_MESSAGE", payload: "This is taking unusually long. You can wait or try again later." });
    }
  }, [state.elapsedTime]);

  const handleFileSelect = async (fileName) => {
    dispatch({ type: "START", payload: fileName });
    dispatch({ type: "TOGGLE_CLASSIFY_MODAL" }); // Lukk modal

    try {
      const updatedContent = { ...content, classificationBlobName: fileName };
      const data = await classifyCategoryData({ content: updatedContent });
      dispatch({ type: "SET_DATA", payload: data });
      dispatch({ type: "SET_MESSAGE", payload: "Processing complete!" });
    } catch (error) {
      console.error("Error classifying content:", error);
      dispatch({
        type: "SET_MESSAGE",
        payload: "An error occurred while processing. Please try again.",
      });

      dispatch({
        type: "SET_DATA",
        payload: { error: error.response || error.message || "Unknown error" },
      });

      dispatch({ type: "STOP_LOADING" }); // Sørg for å stoppe loaderen
    }
  };


  const handleDelete = async () => {
    try {
      await deleteContentItem(content.id);
      dispatch({ type: "SET_FEEDBACK", payload: { message: t("delete_success"), color: "success" } });
      setTimeout(() => onSuccess(), 3000);
    } catch (error) {
      console.error("Error deleting content:", error);
      dispatch({ type: "SET_FEEDBACK", payload: { message: t("delete_content_error"), color: "danger" } });
    }
  };

  const handleCopyUrl = () => {
    navigator.clipboard
        .writeText(window.location.href)
        .then(() => {
          dispatch({ type: "SHOW_COPY_ALERT" });
          setTimeout(() => dispatch({ type: "HIDE_COPY_ALERT" }), 2000);
        })
        .catch((err) => {
          console.error("Error copying URL:", err);
          dispatch({ type: "SET_FEEDBACK", payload: { message: t("copy_failed"), color: "danger" } });
        });
  };

  const handleCreateThread = () => {
    const initialContentData = {
      contentIds: [content.id],
      contentTitles: [content.title],
    };
    navigate("/dialog", { state: initialContentData });
  };

  const handleCloseClassificationInfo = () => {
    dispatch({ type: "RESET_CLASSIFICATION_INFO" });
  };

  return (
      <Container className="content-info">
        <Card>
          <DropdownActions
              handlePrimaryAction={handleCreateThread}
              handleCopyUrl={handleCopyUrl}
              toggleDeleteModal={() => dispatch({ type: "TOGGLE_MODAL" })}
              showCopyAlert={state.showCopyAlert}
              primaryActionIcon={FaPlus}
              primaryActionLabel={t("create_thread")}
              secondaryActions={[
                {
                  onClick: () => dispatch({ type: "TOGGLE_CLASSIFY_MODAL" }),
                  icon: FaTags,
                  label: t("classify_content"),
                },
                {
                  onClick: onEdit,
                  icon: FaEdit,
                  label: t("edit_content"),
                },
              ]}
          />
          <CardBody>
            <ClassificationInfo
                isLoading={state.isLoading}
                classificationData={state.classificationData}
                selectedFile={state.selectedFile}
                onClose={handleCloseClassificationInfo}
            />
            <CardTitle tag="h1" className="text-center mb-4">{content.title}</CardTitle>
            <ContentInfoItem content={content.description} label={t("description")} icon={FaRegFileAlt} />
            <ContentInfoItem content={content.text} label={t("text")} icon={FaAlignLeft} />
            <Row>
              <Col xl="3">
                <ContentInfoItem content={content.type} label={t("type")} icon={FaTag} />
              </Col>
              <Col xl="9">
                <ContentInfoItem url={content.url} label={t("url")} icon={FaLink} />
              </Col>
            </Row>
          </CardBody>
        </Card>
        <ClassificationSelector
            isOpen={state.classifyModalOpen}
            toggle={() => dispatch({ type: "TOGGLE_CLASSIFY_MODAL" })}
            onSelect={handleFileSelect}
        />
        <DeleteModal isOpen={state.modalOpen} toggle={() => dispatch({ type: "TOGGLE_MODAL" })} handleDelete={handleDelete} />
        <FeedbackModal isOpen={state.feedbackModalOpen} message={state.feedbackMessage} color={state.feedbackColor} />
      </Container>
  );
};

const ContentInfoItem = ({ content, label, icon: Icon, url }) => {
  const { theme } = useTheme();
  if (!Icon) return null;
  return (
      <div className="d-flex align-items-center p-3">
        <div className="me-5 d-none d-md-block">
          <Icon className="info-icon" />
        </div>
        <div>
          <h5 style={{ color: theme === "light" ? "var(--primary)" : "var(--primary-dark)" }}>{label}:</h5>
          <CardText>
            <div className="markdown-content scrollable-content">{content}</div>
            {url && (
                <a href={url} target="_blank" rel="noopener noreferrer" className="text-break">
                  {url}
                </a>
            )}
          </CardText>
        </div>
      </div>
  );
};

export default ContentInfo;
