import React, { useEffect, useState, useRef } from "react";
import {
  Badge,
  Card,
  CardBody,
  Container,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupText,
  Label,
  ListGroup,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap";
import { FaEdit, FaRocket } from "react-icons/fa";
import MessageListItem from "./MessageListItem";
import "./MessageList.css";
import { useTranslation } from "react-i18next";
import { useTheme } from "../../theme/theme";
import PrimaryButton, { CancelButton, SecondaryButton } from "../buttons/Button";
import { DeleteModal } from "../deleteModal/DeleteModal";
import { DropdownActions } from "../dropdownAction/DropdownAction";
import FeedbackModal from "../feedbackModal/FeedbackModal";
import useContent from "../../hooks/useContent";
import useThreads from "../../hooks/useThreads";
import useMessages from "../../hooks/useMessages";
import { useNavigate } from "react-router-dom";
import LoadingOverlay from "../loadingOverlay/LoadingOverlay";

const MessageList = ({
                       thread,
                       onAddMessage,
                       onExecuteContextChat,
                       onSuccess,
                       onEditThread,
                     }) => {
  const [newMessage, setNewMessage] = useState("");
  const [showCopyAlert, setShowCopyAlert] = useState(false);
  const [localMessages, setLocalMessages] = useState(thread.messages);
  const { t } = useTranslation();
  const { theme } = useTheme();
  const [modal, setModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [editTitle, setEditTitle] = useState(thread.title);
  const [editDescription, setEditDescription] = useState(thread.description);
  const [loading, setLoading] = useState(false);
  const [feedbackMessage, setFeedbackMessage] = useState("");
  const [feedbackModalOpen, setFeedbackModalOpen] = useState(false);
  const [feedbackColor, setFeedbackColor] = useState("success");
  const { content } = useContent();
  const { deleteThread, editThread } = useThreads();
  const { deleteMessageById } = useMessages();

  const navigate = useNavigate();

  const messagesEndRef = useRef(null);
  const textareaRef = useRef(null);

  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [localMessages]);

  useEffect(() => {
    setLocalMessages(thread.messages);
  }, [thread.messages]);

  const toggleModal = () => setModal(!modal);
  const toggleEditModal = () => setEditModal(!editModal);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (newMessage.trim()) {
      onAddMessage(thread.id, newMessage);
      setNewMessage("");
      if (textareaRef.current) {
        textareaRef.current.style.height = "auto";
      }
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault(); // Hindre linjeskift
      handleSubmit(e);    // Send meldingen
    }
  };

  const onExecute = async () => {
    setLoading(true);
    try {
      await onExecuteContextChat(thread.id);
    } finally {
      setLoading(false);
    }
  };

  const handleCopyUrl = () => {
    navigator.clipboard
        .writeText(window.location.href)
        .then(() => {
          setShowCopyAlert(true);
          setTimeout(() => {
            setShowCopyAlert(false);
          }, 2000);
        })
        .catch(() => {
          alert(t("copy_failed"));
        });
  };

  const handleDelete = async () => {
    try {
      await deleteThread(thread.id);
      toggleModal();
      setFeedbackMessage(t("delete_success"));
      setFeedbackColor("success");
      setFeedbackModalOpen(true);
      setTimeout(() => onSuccess(), 3000);
    } catch (error) {
      console.error("Error deleting dialog:", error);
      setFeedbackMessage(t("delete_thread_error"));
      setFeedbackColor("danger");
      setFeedbackModalOpen(true);
      setTimeout(() => setFeedbackModalOpen(false), 3000);
    }
  };

  const handleDeleteMessage = async (messageId) => {
    try {
      await deleteMessageById(messageId);
      setLocalMessages(localMessages.filter(message => message.id !== messageId));
    } catch (error) {
      console.error('Kunne ikke slette meldingen:', error);
    }
  };

  const handleEditClick = () => {
    setEditTitle(thread.title);
    setEditDescription(thread.description);
    toggleEditModal();
  };

  const handleSaveEdit = async (e) => {
    e.preventDefault();
    try {
      await editThread(thread.id, {
        title: editTitle,
        description: editDescription,
      });
      toggleEditModal();
      onEditThread(thread.id, {
        title: editTitle,
        description: editDescription,
      });
    } catch (error) {
      console.error("Error editing dialog:", error);
      alert(t("edit_thread_error"));
    }
  };

  const handleCancelEdit = () => {
    toggleEditModal();
  };

  const handleBadgeClick = (contextItemId) => {
    navigate(`/content/${contextItemId}`);
  };

  const adjustTextareaHeight = (e) => {
    const textarea = e.target;
    textarea.style.height = "auto";
    textarea.style.height = `${textarea.scrollHeight}px`;
    setNewMessage(textarea.value);
  };

  return (
      <Container fluid className="container-xl">
        <Card
            style={{
              backgroundColor: theme === "light" ? "#ffffff" : "#000000",
              color: theme === "light" ? "#000000" : "#ffffff",
            }}
        >
          <div className="d-flex justify-content-between align-items-center ">
            <div className="ms-4">
              <h5
                  style={{
                    color:
                        theme === "light"
                            ? "var(--primary-light)"
                            : "var(--primary-dark)",
                  }}
              >
                {thread.title}
              </h5>
              <h6 className="mb-2">{thread.description}</h6>
            </div>
            <DropdownActions
                handlePrimaryAction={handleEditClick}
                handleCopyUrl={handleCopyUrl}
                toggleDeleteModal={toggleModal}
                showCopyAlert={showCopyAlert}
                primaryActionIcon={FaEdit}
                primaryActionLabel={t("edit_title")}
            />
          </div>
          <CardBody>
            {thread?.context?.length > 0 ? (
                <div className="ms-2">
                  Selected Contents: {""}
                  {thread.context.map((contextItem) => {
                    const contentTitle =
                        content.find((c) => c.id === contextItem.contentId)?.title ||
                        "Unknown Title";
                    return (
                        <Badge
                            key={contextItem.id}
                            className="me-2 custom-badge"
                            onClick={() => handleBadgeClick(contextItem.contentId)}
                            style={{ cursor: "pointer" }}
                        >
                          {contentTitle}
                        </Badge>
                    );
                  })}
                </div>
            ) : (
                <p className="ms-2">No initial selected contents.</p>
            )}

            <div className="text-center">
              {loading ? (
                  <LoadingOverlay  size="small" isLoading={loading} />
              ) : (
                  <PrimaryButton
                      type="button"
                      color="primary"
                      onClick={onExecute}
                      label={t("execute")}
                      disabled={loading}
                      icon={FaRocket}
                  />
              )}
            </div>

            <div className="messages-item-container flex-grow-1">
              <ListGroup>
                {localMessages &&
                    localMessages.map((message) => (
                        <MessageListItem
                            key={message.id}
                            message={message}
                            onDelete={() => handleDeleteMessage(message.id)}
                        />
                    ))}
                <div ref={messagesEndRef} />
              </ListGroup>
            </div>
            <Form onSubmit={handleSubmit} className="mt-3 ">
              <FormGroup>
                <InputGroup>
                  <Input
                      type="textarea"
                      name="message"
                      id="newMessage"
                      value={newMessage}
                      onChange={adjustTextareaHeight}
                      onKeyDown={handleKeyPress}  // Lytt etter Enter-tasten
                      placeholder={t("enter_your_message")}
                      rows="3"
                      innerRef={textareaRef}
                      style={{
                        resize: "none",
                        overflowY: "auto",
                        maxHeight: "200px",
                      }}
                  />
                  <InputGroupText addonType="append">
                    <PrimaryButton type="submit" label={t("add")} />
                  </InputGroupText>
                </InputGroup>
              </FormGroup>
            </Form>
          </CardBody>
        </Card>

        <DeleteModal
            isOpen={modal}
            toggle={toggleModal}
            handleDelete={handleDelete}
        />
        <FeedbackModal
            isOpen={feedbackModalOpen}
            message={feedbackMessage}
            color={feedbackColor}
        />

        <Modal isOpen={editModal} toggle={toggleEditModal} className="modal-center">
          <ModalHeader toggle={toggleEditModal} className="text-primary">
            {t("edit_title")}
          </ModalHeader>
          <ModalBody>
            <Form onSubmit={handleSaveEdit}>
              <FormGroup>
                <Label for="editTitle">{t("title")}</Label>
                <Input
                    type="text"
                    id="editTitle"
                    value={editTitle}
                    onChange={(e) => setEditTitle(e.target.value)}
                />
              </FormGroup>
              <FormGroup>
                <Label for="editDescription">{t("description")}</Label>
                <Input
                    type="text"
                    id="editDescription"
                    value={editDescription}
                    onChange={(e) => setEditDescription(e.target.value)}
                />
              </FormGroup>
              <div className="text-end">
                <CancelButton
                    type="button"
                    label={t("cancel")}
                    onClick={handleCancelEdit}
                />
                <SecondaryButton type="submit" label={t("save")} />
              </div>
            </Form>
          </ModalBody>
        </Modal>
      </Container>
  );
};

export default MessageList;
