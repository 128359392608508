// src/components/feedbackModal/FeedbackModal.js

import React from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { useTheme } from '../../theme/theme';
import { t } from 'i18next';

const FeedbackModal = ({ isOpen, message, color }) => {
  const { theme } = useTheme();
  return (
    <Modal isOpen={isOpen} centered>
      <ModalHeader
        style={{
          backgroundColor: color === "success" ? "#96EED3" : "#F1ACB6",
          color: "#000000" ,
        }}
      >
        {color === "success" ?  t('success')  : t('error')}
      </ModalHeader>
      <ModalBody
        style={{
          backgroundColor: theme === "light" ? "var(--background-color)" : "var(--background-color-dark)",
          color: theme === "light" ? "#000000" : "#ffffff",
        }}
      >
        {message}
      </ModalBody>
    </Modal>
  );
};

export default FeedbackModal;
