import axios from "axios";

const API_URL = `${process.env.REACT_APP_FAVN_API_BASE_URI}/v1/files`;

// Hent Context-ID fra localStorage
const getContextId = () => localStorage.getItem("selectedContext") || "";

// Hent klassifiseringsfiler
export const fetchFiles = async (accessToken) => {
    const response = await axios.get(API_URL, {
        headers: {
            Authorization: `Bearer ${accessToken}`,
            "Context-Id": getContextId(),
        },
    });
    return response.data;
};

// Last opp fil
export const uploadFile = async (file, accessToken) => {
    const fileContent = await fileToBase64(file);
    const payload = {
        fileName: file.name,
        content: fileContent,
    };
    await axios.post(API_URL, payload, {
        headers: {
            Authorization: `Bearer ${accessToken}`,
            "Context-Id": getContextId(),
            "Content-Type": "application/json",
        },
    });
};

// Slett fil
export const deleteFile = async (fileName, accessToken) => {
    await axios.delete(`${API_URL}/${fileName}`, {
        headers: {
            Authorization: `Bearer ${accessToken}`,
            "Context-Id": getContextId(),
        },
    });
};

// Last ned fil
export const downloadFile = async (fileName, accessToken) => {
    const response = await axios.get(`${API_URL}/${fileName}`, {
        headers: {
            Authorization: `Bearer ${accessToken}`,
            "Context-Id": getContextId(),
        },
        responseType: "blob",
    });
    return response.data;
};

// Konverter fil til Base64
const fileToBase64 = (file) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => resolve(reader.result.split(",")[1]); // Fjerner metadata fra Base64-strengen
        reader.onerror = (error) => reject(error);
        reader.readAsDataURL(file);
    });
