import React, { useEffect, useState } from "react";
import { Spinner } from "reactstrap";
import "./LoadingOverlay.css";
import { useTranslation } from "react-i18next";

const LoadingOverlay = ({ isLoading, onLoadingComplete, size = "default" }) => {
    const { t } = useTranslation();
    const [elapsedTime, setElapsedTime] = useState(0);

    useEffect(() => {
        let timer;
        if (isLoading) {
            timer = setInterval(() => setElapsedTime((time) => time + 1), 1000);
        } else {
            clearInterval(timer);
            if (elapsedTime > 0 && onLoadingComplete) {
                onLoadingComplete();
            }
        }
        return () => clearInterval(timer);
    }, [isLoading, elapsedTime, onLoadingComplete]);

    const formatTime = (time) => {
        const minutes = Math.floor(time / 60);
        const seconds = time % 60;
        return `${minutes} min ${seconds} sec`;
    };

    const getMessage = () => {
        if (elapsedTime < 10) return t("processing_initial");
        if (elapsedTime < 60) return t("processing_taking_longer");
        if (elapsedTime < 120) return t("processing_almost_done");
        return t("processing_unusually_long");
    };

    if (!isLoading) return null;

    return (
        <div className="loading-overlay">
            <div className="loading-content">
                <Spinner size={size === "small" ? "sm" : "lg"} className="loading-icon" />
                {size !== "small" && (
                    <>
                        <p className="loading-message">{getMessage()}</p>
                        <p className="loading-time">{t("elapsed_time", { time: formatTime(elapsedTime) })}</p>
                    </>
                )}
            </div>
        </div>
    );
};

export default LoadingOverlay;
