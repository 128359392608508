import React from 'react';
import { ListGroupItem } from 'reactstrap';
import './DialogListItem.css';
import { useTheme } from '../../theme/theme';
import { formatDate } from '../../utils/dateUtils';

const DialogListItem = ({ thread, onSelectThread, isSelected }) => {
    const { theme } = useTheme();

    return (
        <ListGroupItem
            className={`d-flex justify-content-between align-items-center thread-list-item ${isSelected ? 'selected' : ''}`}
            onClick={() => onSelectThread(thread)}
            style={{
                backgroundColor: theme === 'light' ? '#ffffff' : 'var(--background-color-darker)',
                color: theme === 'light' ? '#000000' : '#ffffff',
            }}
        >
            <div className="d-flex justify-content-between align-items-center w-100">
                <div className="thread-details">
                    <h5 className="mb-2">{thread.title}</h5>
                    <p className="mb-2">{thread.description}</p>
                    <p className="text-end">{formatDate(thread.updated)}</p>
                </div>
            </div>
        </ListGroupItem>
    );
};

export default DialogListItem;
