import { useState, useCallback } from 'react';
import { executeContextchat } from '../api/dialogueApi';
import { useAuth } from '../auth/authContext';

const useDialogue = () => {
    const [chatData, setChatData] = useState(null);
    const [loading, setLoading] = useState(false);
    const { getAccessToken, logout, isInitialized } = useAuth();  // Bruk getAccessToken

    const handle401Error = useCallback(async () => {
        if (!isInitialized) return;
        try {
            await getAccessToken();  // Hent access token
        } catch (silentError) {
            console.error('Silent token acquisition failed:', silentError);
            await logout();
        }
    }, [getAccessToken, logout, isInitialized]);

    const executeContextChat = useCallback(async (threadId) => {
        if (!isInitialized || !threadId) return;
        setLoading(true);
        try {
            const token = await getAccessToken();  // Bruker access token
            if (!token) {
                await handle401Error();
                return;
            }
            const data = await executeContextchat(threadId, token);
            setChatData(data);
            return data;
        } catch (error) {
            if (error.response && error.response.status === 401) {
                await handle401Error();
            } else {
                console.error('Failed to fetch chat data:', error);
            }
        } finally {
            setLoading(false);
        }
    }, [getAccessToken, handle401Error, isInitialized]);

    return {
        chatData,
        loading,
        executeContextChat,
    };
};

export default useDialogue;
