import React, { useEffect, useState } from "react";
import { Container, ListGroup } from "reactstrap";
import ContentListItem from "./ContentListItem";
import "./ContentList.css";
import { useTheme } from "../../theme/theme";
import { useTranslation } from "react-i18next";
import SearchBar from "../searchbar/SearchBar";
import FilterSortBar from "../filterSortBar/FilterSortBar";
import LoadingOverlay from "../loadingOverlay/LoadingOverlay"; // Import FilterSortBar

const ContentList = ({ content, onSelectContent, loading, selectedContent }) => {
  const { t } = useTranslation();
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredContent, setFilteredContent] = useState([]);
  const [sortKey, setSortKey] = useState("latest");
  const [filterKey, setFilterKey] = useState("all");
  const { theme } = useTheme();
  const [dateFilterKey, setDateFilterKey] = useState("all");

  const uniqueTypes = [...new Set(content.map((item) => item.type))];

  useEffect(() => {
    let result = content.filter(
        (item) =>
            (item.title && item.title.toLowerCase().includes(searchTerm.toLowerCase())) ||
            (item.description && item.description.toLowerCase().includes(searchTerm.toLowerCase()))
    );

    if (filterKey !== "all") {
      result = result.filter((item) => item.type === filterKey);
    }

    if (dateFilterKey !== "all") {
      result = result.filter((item) => {
        const now = new Date();
        const createdDate = new Date(item.created);
        const updatedDate = new Date(item.updated);
        let dateToCompare = dateFilterKey.includes("updated") ? updatedDate : createdDate;
        const diffInDays = (now - dateToCompare) / (1000 * 60 * 60 * 24);
        switch (dateFilterKey) {
          case "created_week":
          case "updated_week":
            return diffInDays <= 7;
          case "created_month":
          case "updated_month":
            return diffInDays <= 30;
          case "created_year":
          case "updated_year":
            return diffInDays <= 365;
          default:
            return true;
        }
      });
    }

    result.sort((a, b) => {
      if (sortKey === "latest") {
        return new Date(b.updated) - new Date(a.updated);
      } else if (sortKey === "oldest") {
        return new Date(a.updated) - new Date(b.updated);
      } else if (sortKey === "title") {
        return a.title.localeCompare(b.title);
      }
      return 0;
    });

    setFilteredContent(result);
  }, [filterKey, dateFilterKey, sortKey, searchTerm, content]);

  const handleContentSelect = (content) => {
    onSelectContent(content);
  };

  if (loading) {
    return (
        <Container className="text-center mt-5">
          <LoadingOverlay size="small" isLoading={loading} />
        </Container>
    );
  }

  return (
      <Container
          className="content-list"
          style={{
            backgroundColor: theme === "light" ? "var(--background-color)" : "var(--background-color-dark)",
          }}
      >
        <section
            className="p-2 mb-3 mt-3 position-sticky fixed-top"
            style={{
              background: theme === "light" ? "var(--background-color)" : "var(--background-color-dark)",
              color: theme === "light" ? "#000000" : "#ffffff",
            }}
        >
          <SearchBar searchTerm={searchTerm} setSearchTerm={setSearchTerm} />

          <FilterSortBar
              filterKey={filterKey}
              setFilterKey={setFilterKey}
              sortKey={sortKey}
              setSortKey={setSortKey}
              dateFilterKey={dateFilterKey}
              setDateFilterKey={setDateFilterKey}
              uniqueTypes={uniqueTypes}
              collapsable={true}
          />
        </section>

        {filteredContent.length === 0 ? (
            <Container className="text-center mt-5">
              <p>{t("no_content_available")}</p>
            </Container>
        ) : (
            <ListGroup id="content-list-group">
              {filteredContent.map((item, index) => (
                  <ContentListItem
                      key={index}
                      content={item}
                      onSelectContent={handleContentSelect}
                      isSelected={selectedContent?.id === item.id}
                  />
              ))}
            </ListGroup>
        )}
      </Container>
  );
};

export default ContentList;
