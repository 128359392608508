import React, { useEffect, useState } from "react";
import { Container, ListGroup } from "reactstrap";
import ContextListItem from "./ContextListItem";
import "./ContextList.css";
import SearchBar from "../searchbar/SearchBar";
import LoadingOverlay from "../loadingOverlay/LoadingOverlay";

const ContextList = ({ contexts, onSelectContext, loading, selectedContext }) => {
    const [searchTerm, setSearchTerm] = useState("");
    const [filteredContexts, setFilteredContexts] = useState([]);

    useEffect(() => {
        // Filtrerer kontekster basert på søketerm
        const result = contexts.filter(
            (item) =>
                item.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
                (item.description && item.description.toLowerCase().includes(searchTerm.toLowerCase()))
        );
        setFilteredContexts(result);
    }, [searchTerm, contexts]);

    const handleContextSelect = (context) => {
        onSelectContext(context); // Oppdaterer valgt context i state, men setter den ikke i localStorage
    };

    if (loading) {
        return (
            <Container className="text-center mt-5">
                <LoadingOverlay size="small" isLoading={loading} />
            </Container>
        );
    }

    return (
        <Container className="context-list">
            <SearchBar searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
            {filteredContexts.length === 0 ? (
                <Container className="text-center mt-5">
                    <p>No contexts available</p>
                </Container>
            ) : (
                <ListGroup>
                    {filteredContexts.map((item, index) => (
                        <ContextListItem
                            key={index}
                            context={item}
                            onSelectContext={handleContextSelect}
                            isSelected={selectedContext?.id === item.id} // Markerer valgt context uten å lagre i localStorage
                        />
                    ))}
                </ListGroup>
            )}
        </Container>
    );
};

export default ContextList;
