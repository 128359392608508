import React, { useState } from "react";
import { FormGroup, Label, Input, Row, Col, Collapse } from "reactstrap";
import { useTranslation } from "react-i18next";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import "./FilterSortBar.css"; // Importer CSS

const FilterSortBar = ({
                           filterKey,
                           setFilterKey,
                           sortKey,
                           setSortKey,
                           dateFilterKey,
                           setDateFilterKey,
                           uniqueTypes,
                           pageSize,      // pageSize prop
                           setPageSize,   // setPageSize prop
                           collapsable = false  // Ny prop for kollapsfunksjon
                       }) => {
    const { t } = useTranslation();
    const colSize = pageSize && setPageSize ? 4 : 6; // Dynamisk kolonnebredde

    const [isOpen, setIsOpen] = useState(!collapsable); // Start som åpen hvis ikke collapsable

    const toggleCollapse = () => setIsOpen(!isOpen); // Funksjon for å åpne/lukke

    return (
        <div className="filter-sort-bar">
            {collapsable && (
                <div className="collapse-toggle" onClick={toggleCollapse}>
                    {/* Dynamisk beskrivende tekst og ikon */}
                    {isOpen ? (
                        <>
                            <FaChevronUp size={20} /> <span className="toggle-text">{t("hide_filter")}</span>
                        </>
                    ) : (
                        <>
                            <FaChevronDown size={20} /> <span className="toggle-text">{t("show_filter")}</span>
                        </>
                    )}
                </div>
            )}

            <Collapse isOpen={isOpen}>
                <Row>
                    <Col xs={colSize}>
                        <FormGroup className="form-group">
                            <Label for="filter" className="label">
                                {t("filter")}
                            </Label>
                            <Input
                                type="select"
                                name="filter"
                                id="filter"
                                value={dateFilterKey !== "all" ? dateFilterKey : filterKey}
                                onChange={(e) => {
                                    const value = e.target.value;
                                    if (value.startsWith("created") || value.startsWith("updated")) {
                                        setDateFilterKey(value);
                                        setFilterKey("all");
                                    } else {
                                        setFilterKey(value);
                                        setDateFilterKey("all");
                                    }
                                }}
                                className="input"
                            >
                                <option value="all">{t("all")}</option>
                                <optgroup label={t("by_type")}>
                                    {uniqueTypes.map((type, index) => (
                                        <option key={index} value={type}>
                                            {type}
                                        </option>
                                    ))}
                                </optgroup>
                                <optgroup label={t("by_date")}>
                                    <option value="created_week">{t("created_week")}</option>
                                    <option value="created_month">{t("created_month")}</option>
                                    <option value="created_year">{t("created_year")}</option>
                                    <option value="updated_week">{t("updated_week")}</option>
                                    <option value="updated_month">{t("updated_month")}</option>
                                    <option value="updated_year">{t("updated_year")}</option>
                                </optgroup>
                            </Input>
                        </FormGroup>
                    </Col>

                    <Col xs={colSize}>
                        <FormGroup className="form-group">
                            <Label for="sort" className="label">
                                {t("sort_by")}
                            </Label>
                            <Input
                                type="select"
                                name="sort"
                                id="sort"
                                value={sortKey}
                                onChange={(e) => setSortKey(e.target.value)}
                                className="input"
                            >
                                <option value="title">{t("title")}</option>
                                <option value="latest">{t("latest")}</option>
                                <option value="oldest">{t("oldest")}</option>
                            </Input>
                        </FormGroup>
                    </Col>

                    {pageSize && setPageSize && (
                        <Col xs="4">
                            <FormGroup className="form-group">
                                <Label for="pageSize" className="label">
                                    {t("show_each_page")}
                                </Label>
                                <Input
                                    type="select"
                                    name="pageSize"
                                    id="pageSize"
                                    value={pageSize}
                                    onChange={(e) => setPageSize(Number(e.target.value))}
                                    className="input"
                                >
                                    <option value={5}>5</option>
                                    <option value={10}>10</option>
                                    <option value={20}>20</option>
                                    <option value={50}>50</option>
                                    <option value={100}>100</option>
                                    <option value={200}>200</option>
                                </Input>
                            </FormGroup>
                        </Col>
                    )}
                </Row>
            </Collapse>
        </div>
    );
};

export default FilterSortBar;
