import { useCallback } from 'react';
import { deleteMessage } from '../api/messageApi'; // Importer deleteMessage fra messageApi
import { useAuth } from '../auth/authContext';

const useMessages = () => {
    const { getAccessToken, isInitialized } = useAuth();  // Bruk getAccessToken

    const deleteMessageById = useCallback(async (messageId) => {
        if (!isInitialized) return;
        try {
            const token = await getAccessToken();  // Bruker access token
            await deleteMessage(messageId, token);  // Bruker API-kallet fra messageApi
        } catch (error) {
            console.error('Kunne ikke slette melding:', error);
            throw error;
        }
    }, [getAccessToken, isInitialized]);

    return {
        deleteMessageById,
    };
};

export default useMessages;
