import { useTranslation } from "react-i18next";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import "./DeleteModal.css"

export const DeleteModal = ({ isOpen, toggle, handleDelete }) => {
    const { t } = useTranslation();
    return (
      <Modal isOpen={isOpen} toggle={toggle} className="modal-center custom-modal-width">
        <ModalHeader toggle={toggle} className=" text-danger">{t("delete_confirmation")}</ModalHeader>
        <ModalBody>{t("delete_confirmation_message")}</ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={handleDelete}>
            {t("yes_delete")}
          </Button>{" "}
          <Button color="secondary" onClick={toggle}>
            {t("cancel")}
          </Button>
        </ModalFooter>
      </Modal>
    );
  };