import React from "react";
import { ListGroupItem } from "reactstrap";
import "./ContextListItem.css";

const ContextListItem = ({ context, onSelectContext, isSelected }) => {
    return (
        <ListGroupItem
            onClick={() => onSelectContext(context)}
            className={`context-list-item ${isSelected ? "selected" : ""}`}
        >
            <div>
                <h5>{context.name}</h5>
                <p>{context.description}</p>
            </div>
        </ListGroupItem>
    );
};

export default ContextListItem;
