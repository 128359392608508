import { useState, useEffect, useCallback, useRef } from 'react';
import { fetchContexts, createContext, updateContext, deleteContext as apiDeleteContext } from '../api/contextRegisterApi';
import { useAuth } from '../auth/authContext';
import debounce from 'lodash/debounce';

const useContextRegister = (initialContextId) => {
    const [contexts, setContexts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectedContext, setSelectedContext] = useState(null);
    const { getAccessToken, logout, isInitialized } = useAuth();
    const getContextsRef = useRef(null);

    const handle401Error = useCallback(async () => {
        if (!isInitialized) return;
        try {
            await getAccessToken();
        } catch (silentError) {
            console.error('Silent token acquisition failed:', silentError);
            await logout();
        }
    }, [getAccessToken, logout, isInitialized]);

    const getContexts = useCallback(async () => {
        if (!isInitialized) return;
        setLoading(true);
        try {
            const token = await getAccessToken();
            if (!token) {
                await handle401Error();
                return;
            }
            const data = await fetchContexts(token);
            setContexts(data);
        } catch (error) {
            if (error.response && error.response.status === 401) {
                await handle401Error();
            } else {
                console.error('Failed to fetch contexts:', error);
            }
        } finally {
            setLoading(false);
        }
    }, [getAccessToken, handle401Error, isInitialized]);

    const createContextItem = useCallback(async (newContextData) => {
        if (!isInitialized) return;
        try {
            const token = await getAccessToken();
            const newContext = await createContext(newContextData, token);
            setContexts(prevContexts => [...prevContexts, newContext]);
            return newContext;
        } catch (error) {
            console.error('Failed to create context:', error);
            throw error;
        }
    }, [getAccessToken, isInitialized]);

    const updateContextItem = useCallback(async (id, updatedContextData) => {
        if (!isInitialized) return;
        try {
            const token = await getAccessToken();
            const updatedContext = await updateContext(id, updatedContextData, token);
            setContexts(prevContexts =>
                prevContexts.map(item => (item.id === id ? updatedContext : item))
            );
            return updatedContext;
        } catch (error) {
            console.error('Failed to update context:', error);
            throw error;
        }
    }, [getAccessToken, isInitialized]);

    const deleteContextItem = useCallback(async (id) => {
        if (!isInitialized) return;
        try {
            const token = await getAccessToken();
            await apiDeleteContext(id, token);
            setContexts(prevContexts => prevContexts.filter(item => item.id !== id));
        } catch (error) {
            console.error('Failed to delete context:', error);
            throw error;
        }
    }, [getAccessToken, isInitialized]);

    getContextsRef.current = getContexts;

    useEffect(() => {
        const debouncedGetContexts = debounce(() => {
            getContextsRef.current();
        }, 300);

        debouncedGetContexts();
        return () => {
            debouncedGetContexts.cancel();
        };
    }, []);

    useEffect(() => {
        if (initialContextId && contexts.length) {
            const item = contexts.find(c => c.id === initialContextId);
            setSelectedContext(item || null);
        }
    }, [initialContextId, contexts]);

    return {
        contexts,
        loading,
        selectedContext,
        setSelectedContext,
        getContexts,
        createContextItem,
        updateContextItem,
        deleteContextItem,
    };
};

export default useContextRegister;
