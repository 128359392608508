import React from "react";
import PropTypes from "prop-types";
import { Table, Row, Col, Input } from "reactstrap";
import SearchBar from "../searchbar/SearchBar";
import { useTranslation } from "react-i18next";
import "./GenericList.css";
import LoadingOverlay from "../loadingOverlay/LoadingOverlay";

const GenericList = ({
                         loading,
                         data,
                         columns,
                         searchTerm,
                         setSearchTerm,
                         filterKey,
                         setFilterKey,
                         uniqueFilterKeys,
                         sortKey,
                         setSortKey,
                         onRowClick,
                         renderActions,
                         expandedRow,
                         toggleExpandedRow,
                         rowEqualityCheck = (row1, row2) => row1 === row2, // Standard objektreferansesjekk
                         noResultsMessage,
                     }) => {
    const { t } = useTranslation();

    return (
        <div className="generic-table-container">
            <Row className="mb-3 align-items-center">
                <Col md={filterKey || sortKey ? 6 : 8} className="mx-auto">
                    <SearchBar
                        searchTerm={searchTerm}
                        setSearchTerm={setSearchTerm}
                        placeholder={t("search_placeholder")}
                    />
                </Col>
                {setFilterKey && uniqueFilterKeys && (
                    <Col md="3">
                        <Input
                            type="select"
                            value={filterKey}
                            onChange={(e) => setFilterKey(e.target.value)}
                            aria-label={t("filter")}
                        >
                            <option value="all">{t("all")}</option>
                            {uniqueFilterKeys.map((key) => (
                                <option key={key} value={key}>
                                    {key}
                                </option>
                            ))}
                        </Input>
                    </Col>
                )}
                {setSortKey && (
                    <Col md="3">
                        <Input
                            type="select"
                            value={sortKey}
                            onChange={(e) => setSortKey(e.target.value)}
                            aria-label={t("sort_by")}
                        >
                            <option value="title">{t("sort_by") + " " + t("title")}</option>
                            <option value="latest">{t("sort_by") + " " + t("latest")}</option>
                            <option value="oldest">{t("sort_by") + " " + t("oldest")}</option>
                        </Input>
                    </Col>
                )}
            </Row>

            {loading ? (
                <LoadingOverlay isLoading={loading} />
            ) : data.length === 0 ? (
                <div className="text-center mt-4">{noResultsMessage || t("no_results_found")}</div>
            ) : (
                <div className="table-responsive">
                    <Table striped bordered responsive hover>
                        <thead>
                        <tr>
                            {columns.map((column) => (
                                <th key={column.key}>{column.label}</th>
                            ))}
                            {renderActions && <th>{t("actions")}</th>}
                        </tr>
                        </thead>
                        <tbody>
                        {data.map((item, index) => (
                            <React.Fragment key={index}>
                                <tr
                                    onClick={() => onRowClick && onRowClick(item)}
                                    style={{ cursor: "pointer" }}
                                >
                                    {columns.map((column) => (
                                        <td key={column.key}>
                                            {column.render ? column.render(item) : item[column.key]}
                                        </td>
                                    ))}
                                    {renderActions && (
                                        <td>
                                            <div
                                                className="action-buttons"
                                                onClick={(e) => e.stopPropagation()} // Hindrer radutvidelse fra klikk på knapper
                                            >
                                                {renderActions(item)}
                                            </div>
                                        </td>
                                    )}
                                </tr>
                                {rowEqualityCheck(expandedRow, item) && (
                                    <tr>
                                        <td colSpan={columns.length + (renderActions ? 1 : 0)}>
                                            {toggleExpandedRow ? (
                                                <div className="metadata-section metadata-section-expand">
                                                    {toggleExpandedRow(item)}
                                                </div>
                                            ) : (
                                                <div className="metadata-section">
                                                    <p>{t("No expanded content provided.")}</p>
                                                </div>
                                            )}
                                        </td>
                                    </tr>
                                )}
                            </React.Fragment>
                        ))}
                        </tbody>
                    </Table>
                </div>
            )}
        </div>
    );
};

GenericList.propTypes = {
    loading: PropTypes.bool.isRequired,
    data: PropTypes.array.isRequired,
    columns: PropTypes.arrayOf(
        PropTypes.shape({
            key: PropTypes.string.isRequired,
            label: PropTypes.string.isRequired,
            render: PropTypes.func,
        })
    ).isRequired,
    searchTerm: PropTypes.string.isRequired,
    setSearchTerm: PropTypes.func.isRequired,
    filterKey: PropTypes.string,
    setFilterKey: PropTypes.func,
    uniqueFilterKeys: PropTypes.arrayOf(PropTypes.string),
    sortKey: PropTypes.string,
    setSortKey: PropTypes.func,
    onRowClick: PropTypes.func,
    renderActions: PropTypes.func,
    expandedRow: PropTypes.any,
    toggleExpandedRow: PropTypes.func,
    rowEqualityCheck: PropTypes.func, // Egendefinert funksjon for rad-sammenligning
    noResultsMessage: PropTypes.string,
};

export default GenericList;
