import React, { createContext, useContext, useMemo, useState, useEffect, useCallback } from 'react';
import { PublicClientApplication, InteractionRequiredAuthError } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { msalConfig, loginRequest } from './authConfig';
import { useNavigate } from 'react-router-dom';
import {jwtDecode} from 'jwt-decode'; // Korrigert import

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const msalInstance = useMemo(() => new PublicClientApplication(msalConfig), []);
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [isInitialized, setIsInitialized] = useState(false);
    const navigate = useNavigate();

    const isTokenValid = (token) => {
        try {
            const decodedToken = jwtDecode(token);
            const currentTime = Date.now() / 1000;
            return decodedToken.exp > currentTime;
        } catch (error) {
            console.error("Failed to decode token:", error);
            return false;
        }
    };

    const logout = useCallback(async () => {
        try {
            await msalInstance.logoutPopup();
            setIsAuthenticated(false);
            navigate('/login');
        } catch (error) {
            console.error("Logout failed:", error);
        }
    }, [msalInstance, navigate]);

    useEffect(() => {
        const initializeMsal = async () => {
            try {
                await msalInstance.initialize();
                const response = await msalInstance.handleRedirectPromise();

                if (response !== null) {
                    msalInstance.setActiveAccount(response.account);
                    setIsAuthenticated(true);
                } else {
                    const account = msalInstance.getAllAccounts()[0];
                    if (account) {
                        msalInstance.setActiveAccount(account);
                        const tokenResponse = await msalInstance.acquireTokenSilent({
                            ...loginRequest,
                            account,
                        });

                        if (isTokenValid(tokenResponse.idToken)) {
                            setIsAuthenticated(true);
                        } else {
                            setIsAuthenticated(false);
                            await logout();
                        }
                    } else {
                        setIsAuthenticated(false);
                    }
                }
            } catch (error) {
                console.error("Error during MSAL initialization:", error);
                setIsAuthenticated(false);
            } finally {
                setIsInitialized(true);
            }
        };

        initializeMsal();
    }, [msalInstance, logout]);

    const login = async () => {
        try {
            await msalInstance.loginPopup(loginRequest);
            const account = msalInstance.getAllAccounts()[0];
            if (account) {
                msalInstance.setActiveAccount(account);
                setIsAuthenticated(true);
            }
        } catch (error) {
            console.error("Login failed:", error);
        }
    };

    const getIdToken = async () => {
        if (!isInitialized) {
            console.error("MSAL is not initialized");
            return null;
        }

        const account = msalInstance.getActiveAccount();
        if (!account) {
            console.error("No active account found");
            return null;
        }

        try {
            const response = await msalInstance.acquireTokenSilent({
                ...loginRequest,
                account,
            });
            if (!isTokenValid(response.idToken)) {
                throw new Error("Token expired");
            }
            return response.idToken;
        } catch (error) {
            if (error instanceof InteractionRequiredAuthError || error.message === "Token expired") {
                try {
                    const response = await msalInstance.acquireTokenPopup({
                        ...loginRequest,
                        account,
                    });
                    if (!isTokenValid(response.idToken)) {
                        throw new Error("Token expired");
                    }
                    return response.idToken;
                } catch (popupError) {
                    console.error("Failed to acquire token with popup:", popupError);
                    await logout();
                    return null;
                }
            } else {
                console.error("Failed to acquire token silently:", error);
                await logout();
                return null;
            }
        }
    };

    const getUserId = async () => {
        try {
            const idToken = await getIdToken();
            if (idToken) {
                const decodedToken = jwtDecode(idToken);
                return decodedToken.oid; // Return Azure AD Object ID
            }
            throw new Error("Unable to fetch user ID");
        } catch (error) {
            console.error("Error fetching user ID:", error);
            throw error;
        }
    };

    const getAccessToken = async () => {
        if (!isInitialized) {
            console.error("MSAL is not initialized");
            return null;
        }

        const account = msalInstance.getActiveAccount();
        if (!account) {
            console.error("No active account found");
            return null;
        }

        try {
            const response = await msalInstance.acquireTokenSilent({
                ...loginRequest,
                account,
            });
            if (!isTokenValid(response.accessToken)) {
                throw new Error("Token expired");
            }
            return response.accessToken;
        } catch (error) {
            if (error instanceof InteractionRequiredAuthError || error.message === "Token expired") {
                try {
                    const response = await msalInstance.acquireTokenPopup({
                        ...loginRequest,
                        account,
                    });
                    if (!isTokenValid(response.accessToken)) {
                        throw new Error("Token expired");
                    }
                    return response.accessToken;
                } catch (popupError) {
                    console.error("Failed to acquire token with popup:", popupError);
                    await logout();
                    return null;
                }
            } else {
                console.error("Failed to acquire token silently:", error);
                await logout();
                return null;
            }
        }
    };

    return (
        <AuthContext.Provider value={{ instance: msalInstance, isAuthenticated, isInitialized, login, logout, getIdToken, getAccessToken, getUserId }}>
            <MsalProvider instance={msalInstance}>
                {children}
            </MsalProvider>
        </AuthContext.Provider>
    );
};

export const useAuth = () => useContext(AuthContext);
export const useAuthStatus = () => {
    const context = useAuth();
    return context.isAuthenticated;
};
export const useAuthInitialization = () => {
    const context = useAuth();
    return context.isInitialized;
};
