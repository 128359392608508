import React from "react";
import { Card, CardBody, CardTitle, Container, Row, Col, CardText } from "reactstrap";
import { FaEdit } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import "./ContextInfo.css";
import { SecondaryButton } from "../buttons/Button";

const ContextInfo = ({ context, onEdit, onContextSelect }) => {
    const { t } = useTranslation();
    const selectedContextId = localStorage.getItem('selectedContext');

    // Funksjon for å velge kontekst og lagre i localStorage
    const handleContextSelect = () => {
        localStorage.setItem('selectedContext', context.id);
        if (onContextSelect) {
            onContextSelect(context.id);  // Oppdaterer om nødvendig andre komponenter
        }
        // Oppdater siden etter at en ny kontekst er valgt
        window.location.reload();
    };

    return (
        <Container className="context-info">
            <Card>
                <CardBody>
                    <CardTitle tag="h1" className="text-center mb-5">
                        {context.name}
                    </CardTitle>
                    <Row>
                        <Col>
                            <ContentInfoItem label={t("description")} content={context.description} />
                        </Col>
                    </Row>
                    <div className="button-container d-flex align-items-center">
                        {selectedContextId === context.id ? (
                            <p className="mb-0 me-3 selected-context-text">
                                {t("this_context_is_selected")}
                            </p>
                        ) : (
                            <SecondaryButton
                                label={t("select_context")}
                                onClick={handleContextSelect}
                                className="me-3"
                            />
                        )}
                        <SecondaryButton
                            label={t("edit")}
                            icon={FaEdit}
                            onClick={onEdit}
                        />
                    </div>
                </CardBody>
            </Card>
        </Container>
    );
};

const ContentInfoItem = ({ label, content }) => (
    <div className="p-3">
        <h5>{label}:</h5>
        <CardText>{content}</CardText>
    </div>
);

export default ContextInfo;
