import { useState, useEffect, useCallback, useRef } from 'react';
import { fetchThreads, addThread, addMessage, editThread as apiEditThread, deleteThread } from '../api/threadsApi';
import { useAuth } from '../auth/authContext';
import debounce from 'lodash/debounce';

const useThreads = (initialThreadId) => {
    const [threads, setThreads] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectedThread, setSelectedThread] = useState(null);
    const { getAccessToken, logout, isInitialized } = useAuth();  // Bruk getAccessToken i stedet for getIdToken
    const getThreadsRef = useRef(null); // Ref for å holde getThreads-funksjonen

    const handle401Error = useCallback(async () => {
        if (!isInitialized) return;
        try {
            await getAccessToken();  // Hent access token
        } catch (silentError) {
            console.error('Silent token acquisition failed:', silentError);
            await logout(); // Logg ut hvis token fornyelse feiler
        }
    }, [getAccessToken, logout, isInitialized]);

    const getThreads = useCallback(async () => {
        if (!isInitialized) return;
        setLoading(true);
        try {
            const token = await getAccessToken();  // Bruker access token
            if (!token) {
                await handle401Error(); // Håndter utløpt token
                return;
            }
            const data = await fetchThreads(token);
            setThreads(data.map(thread => ({ ...thread, messages: thread.messages || [] }))); // Sørg for at messages er en array
        } catch (error) {
            if (error.response && error.response.status === 401) {
                await handle401Error(); // Håndter utløpt token
            } else {
                console.error('Failed to fetch threads:', error);
            }
        } finally {
            setLoading(false);
        }
    }, [getAccessToken, handle401Error, isInitialized]);

    // Store getThreads-funksjonen i ref-en
    getThreadsRef.current = getThreads;

    useEffect(() => {
        const debouncedGetThreads = debounce(() => {
            getThreadsRef.current();
        }, 300);

        debouncedGetThreads();
        return () => {
            debouncedGetThreads.cancel();
        };
    }, []); // Ingen avhengigheter nødvendig, siden ref-en oppdateres

    useEffect(() => {
        if (initialThreadId && threads.length) {
            const thread = threads.find(t => t.id === initialThreadId);
            setSelectedThread(thread || null);
        }
    }, [initialThreadId, threads]);

    const createThread = useCallback(async (threadData) => {
        if (!isInitialized) return;
        try {
            const token = await getAccessToken();  // Bruker access token
            const newThread = await addThread(threadData, token);
            setThreads(prevThreads => [...prevThreads, { ...newThread, messages: newThread.messages || [] }]); // Sørg for at messages er en array
            return newThread;
        } catch (error) {
            console.error('Failed to create dialog:', error);
            throw error;
        }
    }, [getAccessToken, isInitialized]);

    const addMessageToThread = useCallback(async (threadId, messageData) => {
        if (!isInitialized) return;
        try {
            const token = await getAccessToken();  // Bruker access token
            const newMessage = await addMessage(threadId, messageData, token);
            setThreads(prevThreads =>
                prevThreads.map(thread =>
                    thread.id === threadId
                        ? { ...thread, messages: [...(thread.messages || []), newMessage] } // Sørg for at messages er en array
                        : thread
                )
            );
            return newMessage;
        } catch (error) {
            console.error('Failed to add message:', error);
            throw error;
        }
    }, [getAccessToken, isInitialized]);

    const editThread = useCallback(async (threadId, updatedThreadData) => {
        if (!isInitialized) return;
        try {
            const token = await getAccessToken();  // Bruker access token
            const updatedThread = await apiEditThread(threadId, updatedThreadData, token);
            setThreads(prevThreads =>
                prevThreads.map(thread =>
                    thread.id === threadId
                        ? { ...thread, ...updatedThread }
                        : thread
                )
            );
            return updatedThread;
        } catch (error) {
            console.error('Failed to edit dialog:', error);
            throw error;
        }
    }, [getAccessToken, isInitialized]);

    const deleteThreadById = useCallback(async (threadId) => {
        if (!isInitialized) return;
        try {
            const token = await getAccessToken();  // Bruker access token
            await deleteThread(threadId, token);
            setThreads(prevThreads => prevThreads.filter(thread => thread.id !== threadId));
        } catch (error) {
            console.error('Failed to delete dialog:', error);
            throw error;
        }
    }, [getAccessToken, isInitialized]);

    return {
        threads,
        loading,
        selectedThread,
        setSelectedThread,
        getThreads,
        createThread,
        addMessageToThread,
        editThread, // Legg til editThread her
        deleteThread: deleteThreadById,
    };
};

export default useThreads;
