import { useCallback } from "react";
import { fetchFiles, uploadFile, deleteFile, downloadFile } from "../api/classificationApi"; // Opprett et API-modul
import { useAuth } from "../auth/authContext";

const useClassificationFiles = () => {
    const { getAccessToken } = useAuth();

    // Hent klassifiseringsfiler
    const fetchClassificationFiles = useCallback(async () => {
        try {
            const token = await getAccessToken();
            const files = await fetchFiles(token); // Hent filer via backend
            return files;
        } catch (error) {
            console.error("Error fetching classification files:", error);
            throw error;
        }
    }, [getAccessToken]);

    // Last opp fil
    const uploadClassificationFile = useCallback(async (file) => {
        try {
            const token = await getAccessToken();
            await uploadFile(file, token); // Send JSON til backend
        } catch (error) {
            console.error("Error uploading file:", error);
            throw error;
        }
    }, [getAccessToken]);

    // Slett fil
    const deleteClassificationFile = useCallback(async (fileName) => {
        try {
            const token = await getAccessToken();
            await deleteFile(fileName, token); // Slett fil via backend
            console.log("Classification file deleted successfully:", fileName);
        } catch (error) {
            console.error("Error deleting classification file:", error);
            throw error;
        }
    }, [getAccessToken]);

    // Last ned fil
    const downloadClassificationFile = useCallback(async (fileName) => {
        try {
            const token = await getAccessToken();
            const blob = await downloadFile(fileName, token); // Last ned fil via backend
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", fileName);
            document.body.appendChild(link);
            link.click();
            console.log("Classification file downloaded successfully:", fileName);
        } catch (error) {
            console.error("Error downloading classification file:", error);
            throw error;
        }
    }, [getAccessToken]);

    return {
        fetchClassificationFiles,
        uploadClassificationFile,
        deleteClassificationFile,
        downloadClassificationFile,
    };
};

export default useClassificationFiles;
