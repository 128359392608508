import React from 'react';
import { useAuth } from '../auth/authContext';
import LoggedInHome from './LoggedInHome';
import LoggedOutHome from './LoggedOutHome';
import './Home.css';

const HomePage = () => {
    const { isAuthenticated } = useAuth();

    return (
        <>
            {isAuthenticated ? <LoggedInHome /> : <LoggedOutHome />}
        </>
    );
};

export default HomePage;
