import React, { useState, useEffect } from "react";
import {
    Container,
    Form,
    FormGroup,
    Label,
    Input,
    Card,
    CardBody,
    CardTitle,
    Modal,
    ModalHeader,
    ModalBody,
} from "reactstrap";
import { FaPaperPlane, FaTimes } from "react-icons/fa";
import { useTheme } from "../../theme/theme";
import { useTranslation } from "react-i18next";
import { CancelButton, SecondaryButton } from "../buttons/Button";
import "./ContentForm.css";
import useContent from "../../hooks/useContent";

const ContentForm = ({ content, onSuccess, onCancel }) => {
    const { theme } = useTheme();
    const { t } = useTranslation();
    const { createContentItem, updateContentItem } = useContent();

    const [contentData, setContentData] = useState({
        description: "",
        text: "",
        title: "",
        type: "",
        url: "",
    });
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [feedbackMessage, setFeedbackMessage] = useState("");
    const [feedbackModalOpen, setFeedbackModalOpen] = useState(false);
    const [feedbackColor, setFeedbackColor] = useState("success");

    useEffect(() => {
        setContentData(
            content || {
                description: "",
                text: "",
                title: "",
                type: "",
                url: "",
            }
        );
    }, [content]);

    useEffect(() => {
        if (feedbackModalOpen) {
            const timer = setTimeout(() => setFeedbackModalOpen(false), 3000);
            return () => clearTimeout(timer);
        }
    }, [feedbackModalOpen]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setContentData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);
        try {
            let newContent;
            if (content && content.id) {
                newContent = await updateContentItem(content.id, contentData);
            } else {
                newContent = await createContentItem(contentData);
            }
            setFeedbackMessage(t("content_success"));
            setFeedbackColor("success");
            setFeedbackModalOpen(true);
            setTimeout(() => onSuccess(newContent), 3000);
        } catch (error) {
            console.error("Error submitting content:", error);
            setFeedbackMessage(t("content_error"));
            setFeedbackColor("danger");
            setFeedbackModalOpen(true);
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <Container className="content-form">
            <Card
                style={{
                    backgroundColor: theme === "light" ? "#ffffff" : "#000000",
                    color: theme === "light" ? "#000000" : "#ffffff",
                }}
            >
                <CardBody>
                    <CardTitle
                        tag="h1"
                        className="text-center"
                        style={{
                            color: theme === "light" ? "var(--primary)" : "var(--primary-dark)",
                        }}
                    >
                        {content && content.id ? t("edit_content") : t("add_new_content")}
                    </CardTitle>

                    {/* Skjemaet starter her */}
                    <Form onSubmit={handleSubmit}>
                        <FormField
                            type="text"
                            name="title"
                            id="title"
                            value={contentData.title}
                            onChange={handleChange}
                            disabled={isSubmitting}
                            label={t("title")}
                        />
                        <FormField
                            type="textarea"
                            name="description"
                            id="description"
                            value={contentData.description}
                            onChange={handleChange}
                            disabled={isSubmitting}
                            label={t("description")}
                        />
                        <FormField
                            type="textarea"
                            name="text"
                            id="text"
                            value={contentData.text}
                            onChange={handleChange}
                            disabled={isSubmitting}
                            label={t("text")}
                        />
                        <FormField
                            type="text"
                            name="type"
                            id="type"
                            value={contentData.type}
                            onChange={handleChange}
                            disabled={isSubmitting}
                            label={t("type")}
                        />
                        <FormField
                            type="url"
                            name="url"
                            id="url"
                            value={contentData.url}
                            onChange={handleChange}
                            disabled={isSubmitting}
                            label={t("url")}
                        />

                        {/* Knappene nederst */}
                        <div className="d-flex justify-content-center mt-3">
                            <CancelButton
                                label={t("cancel")}
                                icon={FaTimes}
                                type="button"
                                onClick={onCancel}
                            />
                            <SecondaryButton
                                type="button"
                                disabled={isSubmitting}
                                icon={FaPaperPlane}
                                label={isSubmitting ? t("submitting") : t("submit")}
                                onClick={handleSubmit}
                            />
                        </div>
                    </Form>
                </CardBody>
            </Card>

            {/* Tilbakemeldingsmodal */}
            <Modal
                isOpen={feedbackModalOpen}
                toggle={() => setFeedbackModalOpen(false)}
                className="modal-center"
                contentClassName="modal-content"
            >
                <ModalHeader
                    toggle={() => setFeedbackModalOpen(false)}
                    style={{
                        backgroundColor:
                            feedbackColor === "success" ? "green" : "red",
                        color: "#ffffff",
                    }}
                >
                    {feedbackColor === "success" ? t("success") : t("error")}
                </ModalHeader>
                <ModalBody>{feedbackMessage}</ModalBody>
            </Modal>
        </Container>
    );
};

const FormField = ({ label, type, name, id, value, onChange, disabled }) => {
    const { theme } = useTheme();
    const [textareaHeight, setTextareaHeight] = useState("auto");

    useEffect(() => {
        if (type === "textarea") {
            setTextareaHeight("auto");
            const textarea = document.getElementById(id);
            if (textarea) {
                setTextareaHeight(`${textarea.scrollHeight}px`);
            }
        }
    }, [value, id, type]);

    const handleInputChange = (e) => {
        onChange(e);
        if (type === "textarea") {
            const textarea = e.target;
            setTextareaHeight(`${textarea.scrollHeight}px`);
        }
    };

    return (
        <FormGroup className="p-3">
            <Label for={id}>{label}</Label>
            <Input
                type={type}
                name={name}
                id={id}
                value={value}
                onChange={handleInputChange}
                disabled={disabled}
                className="input-field"
                style={{
                    backgroundColor: theme === "light" ? "#ffffff" : "#333333",
                    color: theme === "light" ? "#000000" : "#ffffff",
                    height: type === "textarea" ? textareaHeight : "auto",
                    overflowY: "auto", // Vertikal scroll
                    overflowX: "hidden", // Forhindrer horisontal scroll
                }}
            />
        </FormGroup>
    );
};

export default ContentForm;
