import React, { useState, useEffect } from "react";
import {
    Container,
    Form,
    FormGroup,
    Label,
    Input,
    Card,
    CardBody,
    CardTitle,
} from "reactstrap";
import { FaTimes, FaPaperPlane } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import { CancelButton, SecondaryButton } from "../buttons/Button";
import useContextRegister from "../../hooks/useContextRegister";
import "./ContextForm.css";

const ContextForm = ({ context, onSuccess, onCancel }) => {
    const { t } = useTranslation();
    const { createContextItem, updateContextItem } = useContextRegister();
    const [contextData, setContextData] = useState({
        name: "",
        description: "",
        type: "",
    });
    const [isSubmitting, setIsSubmitting] = useState(false);

    useEffect(() => {
        setContextData(
            context || {
                name: "",
                description: "",
                type: "",
            }
        );
    }, [context]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setContextData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);
        try {
            let newContext;
            if (context && context.id) {
                newContext = await updateContextItem(context.id, contextData);
            } else {
                newContext = await createContextItem(contextData);
            }
            onSuccess(newContext);
        } catch (error) {
            console.error("Error submitting context:", error);
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <Container className="context-form">
            <Card>
                <CardBody>
                    <CardTitle tag="h1" className="text-center">
                        {context && context.id ? t("edit_context") : t("add_new_context")}
                    </CardTitle>
                    <Form onSubmit={handleSubmit}>
                        <FormField
                            type="text"
                            name="name"
                            id="name"
                            value={contextData.name}
                            onChange={handleChange}
                            disabled={isSubmitting}
                            label={t("name")}
                        />
                        <FormField
                            type="textarea"
                            name="description"
                            id="description"
                            value={contextData.description}
                            onChange={handleChange}
                            disabled={isSubmitting}
                            label={t("description")}
                        />
                        <FormField
                            type="text"
                            name="type"
                            id="type"
                            value={contextData.type}
                            onChange={handleChange}
                            disabled={isSubmitting}
                            label={t("type")}
                        />
                        <div className="d-flex justify-content-center">
                            <CancelButton
                                label={t("cancel")}
                                icon={FaTimes}
                                type="button"
                                onClick={onCancel}
                            />
                            <SecondaryButton
                                type="submit"
                                icon={FaPaperPlane}
                                label={isSubmitting ? t("submitting") : t("submit")}
                                disabled={isSubmitting}
                            />
                        </div>
                    </Form>
                </CardBody>
            </Card>
        </Container>
    );
};

const FormField = ({ label, type, name, id, value, onChange, disabled }) => (
    <FormGroup className="p-3">
        <Label for={id} tag="h5">
            {label}
        </Label>
        <Input
            type={type}
            name={name}
            id={id}
            value={value}
            onChange={onChange}
            disabled={disabled}
        />
    </FormGroup>
);

export default ContextForm;
