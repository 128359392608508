import { useState, useEffect, useCallback, useRef } from 'react';
import { fetchContent, createContent, updateContent, deleteContent as apiDeleteContent } from '../api/contentApi';
import { useAuth } from '../auth/authContext';
import debounce from 'lodash/debounce';

const useContent = (initialContentId) => {
    const [content, setContent] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectedContent, setSelectedContent] = useState(null);
    const { getAccessToken, logout, isInitialized } = useAuth();
    const getContentRef = useRef(null);

    const handle401Error = useCallback(async () => {
        if (!isInitialized) return;
        try {
            await getAccessToken();
        } catch (silentError) {
            console.error('Silent token acquisition failed:', silentError);
            await logout();
        }
    }, [getAccessToken, logout, isInitialized]);

    const getContent = useCallback(async () => {
        if (!isInitialized) return;
        setLoading(true);
        try {
            const token = await getAccessToken();
            if (!token) {
                await handle401Error();
                return;
            }
            const data = await fetchContent(token);
            setContent(data);
        } catch (error) {
            if (error.response && error.response.status === 401) {
                await handle401Error();
            } else {
                console.error('Failed to fetch content:', error);
            }
        } finally {
            setLoading(false);
        }
    }, [getAccessToken, handle401Error, isInitialized]);

    const createContentItem = useCallback(async (newContentData) => {
        if (!isInitialized) return;
        try {
            const token = await getAccessToken();
            const newContent = await createContent(newContentData, token);
            setContent(prevContent => [...prevContent, newContent]);
            return newContent;
        } catch (error) {
            console.error('Failed to create content:', error);
            throw error;
        }
    }, [getAccessToken, isInitialized]);

    const updateContentItem = useCallback(async (id, updatedContentData) => {
        if (!isInitialized) return;
        try {
            const token = await getAccessToken();
            const updatedContent = await updateContent(id, updatedContentData, token);
            setContent(prevContent =>
                prevContent.map(item => (item.id === id ? updatedContent : item))
            );
            return updatedContent;
        } catch (error) {
            console.error('Failed to update content:', error);
            throw error;
        }
    }, [getAccessToken, isInitialized]);

    const deleteContentItem = useCallback(async (id) => {
        if (!isInitialized) return;
        try {
            const token = await getAccessToken();
            await apiDeleteContent(id, token);
            setContent(prevContent => prevContent.filter(item => item.id !== id));
        } catch (error) {
            console.error('Failed to delete content:', error);
            throw error;
        }
    }, [getAccessToken, isInitialized]);

    getContentRef.current = getContent;

    useEffect(() => {
        const debouncedGetContent = debounce(() => {
            getContentRef.current();
        }, 300);

        debouncedGetContent();
        return () => {
            debouncedGetContent.cancel();
        };
    }, []);

    useEffect(() => {
        if (initialContentId && content.length) {
            const item = content.find(c => c.id === initialContentId);
            setSelectedContent(item || null);
        }
    }, [initialContentId, content]);

    return {
        content,
        loading,
        selectedContent,
        setSelectedContent,
        getContent,
        createContentItem,
        updateContentItem,
        deleteContentItem,
    };
};

export default useContent;
