import axios from 'axios';

const API_URL = `${process.env.REACT_APP_FAVN_API_BASE_URI}/v1/actions/dialogue/contentdialogue`;

export const executeContextchat = async (id, accessToken) => {
    try {
        const contextId = localStorage.getItem('selectedContext');
        const response = await axios.get(`${API_URL}?threadId=${id}`, {
            headers: {
                Authorization: `Bearer ${accessToken}`,
                'Context-Id': contextId || '',  // Legger til Context-Id fra localStorage
            },
        });
        return response.data;
    } catch (error) {
        console.error('Error fetching content:', error);
        throw error;
    }
};
