import React, { useState, useEffect, useCallback } from "react";
import { Container, Row, Col } from "reactstrap";
import { FaPlus, FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { useParams, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useContextRegister from "../hooks/useContextRegister";
import ContextForm from "../components/context/ContextForm";
import ContextList from "../components/context/ContextList";
import ContextInfo from "../components/context/ContextInfo";
import PrimaryButton from "../components/buttons/Button";
import "./Context.css";

const Context = () => {
    const { contextId } = useParams();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { contexts, loading, selectedContext, setSelectedContext, getContexts } = useContextRegister(contextId);

    const [showAddForm, setShowAddForm] = useState(false);
    const [showEditForm, setShowEditForm] = useState(false);
    const [isContextListVisible, setIsContextListVisible] = useState(true);

    useEffect(() => {
        if (contextId && contexts.length > 0) {
            const selected = contexts.find((item) => item.id === contextId);
            if (selected && selected.id !== selectedContext?.id) {
                setSelectedContext(selected);
            }
        } else if (!contextId) {
            setSelectedContext(null);
        }
    }, [contextId, contexts, setSelectedContext, selectedContext]);

    useEffect(() => {
        if (selectedContext && selectedContext.id !== contextId) {
            navigate(`/context/${selectedContext.id}`, { replace: true });
        }
    }, [selectedContext, navigate, contextId]);

    const handleContextSelect = useCallback(
        (item) => {
            if (item.id !== contextId) {
                setSelectedContext(item);
                navigate(`/context/${item.id}`, { replace: true });

                if (window.innerWidth <= 768) {
                    setIsContextListVisible(false);
                }
            }
        },
        [contextId, setSelectedContext, navigate]
    );

    const handleAddNewContext = () => {
        setSelectedContext(null);
        navigate(`/context`);
        setShowAddForm(true);
        setShowEditForm(false);
    };

    const handleEditContext = () => {
        setShowEditForm(true);
        setShowAddForm(false);
    };

    const handleSuccess = useCallback(
        (newContext) => {
            setSelectedContext(newContext);
            setShowAddForm(false);
            setShowEditForm(false);
            getContexts();

            if (newContext && newContext.id) {
                navigate(`/context/${newContext.id}`, { replace: true });
            } else {
                navigate(`/context/${contextId}`, { replace: true });
            }
        },
        [getContexts, setSelectedContext, navigate, contextId]
    );

    const handleCancel = () => {
        setShowAddForm(false);
        setShowEditForm(false);
    };

    const toggleContextList = () => {
        setIsContextListVisible((prev) => !prev);
    };

    return (
        <Container fluid className="context-container min-vh-100">
            <button className="toggle-button" onClick={toggleContextList}>
                {isContextListVisible ? <FaChevronLeft /> : <FaChevronRight />}
            </button>

            <Row className="h-100">
                {isContextListVisible && (
                    <Col
                        md="4"
                        xl="3"
                        className={`d-flex flex-column contextlist-slide ${isContextListVisible ? "" : "contextlist-slide-hidden"}`}
                    >
                        <ContextList
                            contexts={contexts}
                            onSelectContext={handleContextSelect}
                            loading={loading}
                            selectedContext={selectedContext}
                        />
                    </Col>
                )}
                <Col md={isContextListVisible ? "8" : "12"} xl={isContextListVisible ? "9" : "12"} className="d-flex flex-column">
                    <Row className="add-button-container">
                        <div className="text-end">
                            <PrimaryButton label={t("add_new_context")} icon={FaPlus} onClick={handleAddNewContext} />
                        </div>
                    </Row>
                    {selectedContext && !showEditForm ? (
                        <ContextInfo onSuccess={handleSuccess} context={selectedContext} onEdit={handleEditContext} />
                    ) : showAddForm ? (
                        <ContextForm context={null} onSuccess={handleSuccess} onCancel={handleCancel} />
                    ) : showEditForm ? (
                        <ContextForm context={selectedContext} onSuccess={handleSuccess} onCancel={handleCancel} />
                    ) : (
                        <div className="empty-state">
                            <h4>{t("select_or_add_context")}</h4>
                        </div>
                    )}
                </Col>
            </Row>
        </Container>
    );
};

export default Context;
