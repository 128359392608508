import React, { useState, useEffect } from 'react';
import { useAuth, useAuthInitialization } from '../auth/authContext';
import { Card, CardBody, CardTitle, CardText, Container, Row, Col } from 'reactstrap';
import { FaUser, FaEnvelope } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import { jwtDecode } from 'jwt-decode'; // Merk riktig import som jwt_decode
import './Profile.css';
import PrimaryButton from "../components/buttons/Button"; // Legger til knappen

const Profile = () => {
    const { getIdToken, logout } = useAuth(); // Legg til logout her
    const isInitialized = useAuthInitialization();
    const [userData, setUserData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const { t } = useTranslation();

    useEffect(() => {
        if (!isInitialized) {
            return;
        }

        const fetchUserData = async () => {
            try {
                const idToken = await getIdToken();
                if (!idToken) {
                    throw new Error("No ID token available");
                }

                // Dekode ID-tokenet for å hente brukerens informasjon
                const decodedToken = jwtDecode(idToken);  // Endret til jwt_decode
                setUserData(decodedToken);
            } catch (err) {
                console.error("Error fetching user data from ID token:", err);
                setError(err);
            } finally {
                setLoading(false);
            }
        };

        fetchUserData();
    }, [isInitialized, getIdToken]);

    if (loading) {
        return <p>{t('loading')}</p>;
    }

    if (error) {
        return <p>{t('error_fetching_user_data', { message: error.message })}</p>;
    }

    return (
        <Container className="min-vh-100">
            <Row className="justify-content-center">
                <Col md="8" lg="6">
                    <Card className="profile-card shadow-lg">
                        <CardBody>
                            <CardTitle tag="h3" className="text-center text-dark">{t('profile')}</CardTitle>
                            {userData ? (
                                <div className="profile-details">
                                    <CardText><FaUser /> <strong>{t('name')}:</strong> {userData.name || userData.given_name + " " + userData.family_name}</CardText>
                                    <CardText><FaEnvelope /> <strong>{t('email')}:</strong> {userData.email || userData.preferred_username}</CardText>
                                </div>
                            ) : (
                                <CardText>{t('no_user_data')}</CardText>
                            )}

                            {/* Legg til logout-knappen */}
                            <div className="text-center mt-4">
                                <PrimaryButton label={t("logout")} onClick={logout} />
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
};

export default Profile;
