import axios from 'axios';

const API_URL = `${process.env.REACT_APP_FAVN_API_BASE_URI}/v1/contextregister/context`;

const fetchData = async (url, accessToken, method = 'get', data = null) => {
    try {
        const contextId = localStorage.getItem('selectedContext');
        const headers = {
            Authorization: `Bearer ${accessToken}`,
            'Context-Id': contextId || '',  // Legger til Context-Id fra localStorage
        };
        const config = {
            method,
            url,
            headers,
            data,
        };
        const response = await axios(config);
        return response.data;
    } catch (error) {
        console.error(`Error fetching data from ${url}:`, error);
        throw error;
    }
};

export const fetchContexts = async (accessToken) => {
    return await fetchData(API_URL, accessToken);
};

export const createContext = async (newContext, accessToken) => {
    return await fetchData(API_URL, accessToken, 'post', newContext);
};

export const updateContext = async (id, updatedContext, accessToken) => {
    return await fetchData(`${API_URL}/${id}`, accessToken, 'patch', updatedContext);
};

export const deleteContext = async (id, accessToken) => {
    return await fetchData(`${API_URL}/${id}`, accessToken, 'delete');
};
