import React, { useState, useEffect } from "react";
import { Modal, ModalHeader, ModalBody, ListGroup, ListGroupItem } from "reactstrap";
import useClassificationFiles from "../../hooks/useClassificationFiles";
import LoadingOverlay from "../loadingOverlay/LoadingOverlay";

const ClassificationSelector = ({ isOpen, toggle, onSelect }) => {
    const [files, setFiles] = useState([]);
    const [loading, setLoading] = useState(false);
    const [hasFetched, setHasFetched] = useState(false); // Ny tilstand for å spore om filer allerede er lastet
    const { fetchClassificationFiles } = useClassificationFiles();

    useEffect(() => {
        // Kun kjør når modalen åpnes og filer ikke allerede er lastet
        if (isOpen && !hasFetched) {
            const loadFiles = async () => {
                setLoading(true);
                try {
                    const fetchedFiles = await fetchClassificationFiles();
                    setFiles(fetchedFiles);
                    setHasFetched(true); // Marker at filer er lastet
                } catch (error) {
                    console.error("Error fetching files:", error);
                } finally {
                    setLoading(false);
                }
            };
            loadFiles();
        }
    }, [isOpen, hasFetched, fetchClassificationFiles]);

    // Tilbakestill hasFetched når modalen lukkes
    useEffect(() => {
        if (!isOpen) {
            setHasFetched(false);
        }
    }, [isOpen]);

    return (
        <Modal
            isOpen={isOpen}
            toggle={toggle}
            className="classification-modal"
            centered
        >
            <ModalHeader toggle={toggle}>Select a File</ModalHeader>
            <ModalBody>
                {loading ? (
                    <LoadingOverlay size="small" isLoading={loading} />
                ) : files.length > 0 ? (
                    <ListGroup>
                        {files.map((file) => (
                            <ListGroupItem key={file.name} onClick={() => onSelect(file.name)} action>
                                {file.name}
                            </ListGroupItem>
                        ))}
                    </ListGroup>
                ) : (
                    <p>No files available.</p>
                )}
            </ModalBody>
        </Modal>
    );
};

export default ClassificationSelector;
