// src/components/NavItem.js

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { NavLink as RouterNavLink } from 'react-router-dom';
import { NavItem as ReactstrapNavItem, NavLink } from 'reactstrap';

const NavBarItem = ({ to, icon, label, onClick }) => (
    <ReactstrapNavItem>
        <NavLink tag={RouterNavLink} to={to} className="nav-link" onClick={onClick}>
        <FontAwesomeIcon icon={icon} /> {label}
        </NavLink>
    </ReactstrapNavItem>
);

export default NavBarItem;
