import React, { useEffect, useState, useCallback } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";
import DialogList from "../components/dialog/DialogList";
import MessageList from "../components/message/MessageList";
import ModelList from "../components/model/ModelList";
import NewDialogForm from "../components/dialog/NewDialogForm";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import useThreads from "../hooks/useThreads";
import useDialogue from "../hooks/useDialogue"; // Importer useDialogue
import "./Dialog.css";
import { useTranslation } from "react-i18next";

const Dialog = () => {
  const { threadId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { threads, loading, selectedThread, setSelectedThread, getThreads, createThread, addMessageToThread } = useThreads(threadId);
  const { executeContextChat } = useDialogue(); // Bruk useDialogue
  const [initialContentState, setInitialContentState] = useState(location.state || {});
  const [isThreadListVisible, setIsThreadListVisible] = useState(true);
  const { t } = useTranslation();

  useEffect(() => {
    if (threadId) {
      const selectedThread = threads.find((thread) => thread.id === threadId);
      if (selectedThread) {
        setSelectedThread(selectedThread);
      } else {
        setSelectedThread(null);
      }
    } else {
      setSelectedThread(null);
    }
  }, [threadId, threads, setSelectedThread]);

  useEffect(() => {
    if (selectedThread && selectedThread.id !== threadId) {
      navigate(`/dialog/${selectedThread.id}`, { replace: true });
    }
  }, [selectedThread, navigate, threadId]);

  const handleThreadClick = (thread) => {
    if (thread.id !== threadId) {
      setSelectedThread(thread);
      if (window.innerWidth <= 768) {
        setIsThreadListVisible(false);
      }
    }
  };

  const handleAddThread = async (newThread) => {
    try {
      const addedThread = await createThread(newThread);
      await getThreads();
      setSelectedThread(addedThread);
      setInitialContentState(null);
      navigate("/dialog", { replace: true });
    } catch (error) {
      console.error("Failed to add dialog:", error);
    }
  };

  const handleAddMessage = async (threadId, content) => {
    const newMessage = {
      content,
      role: "user",
    };
    try {
      await addMessageToThread(threadId, newMessage);
      setSelectedThread((prevThread) => ({
        ...prevThread,
        messages: [...(prevThread.messages || []), newMessage],
      }));
    } catch (error) {
      console.error("Failed to add message:", error);
    }
  };

  const handleExecuteContextChat = async (threadId) => {
    try {
      const chatData = await executeContextChat(threadId); // Kall executeContextChat når brukeren klikker på Execute-knappen
      setSelectedThread(chatData);
    } catch (error) {
      console.error("Failed to execute context chat:", error);
    }
  };

  const handleSuccess = useCallback(() => {
    setSelectedThread(null);
    setInitialContentState(null);
    getThreads();
  }, [getThreads, setSelectedThread]);

  const toggleThreadList = () => {
    setIsThreadListVisible((prev) => !prev);
  };

  return (
      <Container fluid className="dialog-container min-vh-100">
        <button className="toggle-button" onClick={toggleThreadList}>
          {isThreadListVisible ? <FaChevronLeft /> : <FaChevronRight />}
        </button>

        <Row className="h-100 ">
          {isThreadListVisible && (
              <Col
                  md="4"
                  xl="3"
                  className={`d-flex flex-column threadlist-slide ${
                      isThreadListVisible ? "" : "threadlist-slide-hidden"
                  }`}
              >
                <DialogList
                    threads={threads}
                    onSelectThread={handleThreadClick}
                    loading={loading}
                    selectedThread={selectedThread}
                />
              </Col>
          )}
          <Col
              md={isThreadListVisible ? "8" : "12"}
              xl={isThreadListVisible ? "9" : "12"}
              className="d-flex flex-column "
          >
            <Row className="align-items-center">
              <Col sm="6" lg="4">
                <ModelList />
              </Col>
              <Col sm="6" lg="8">
                <NewDialogForm
                    onAddThread={handleAddThread}
                    initialContentId={initialContentState?.contentIds || []}
                    initialContentTitles={initialContentState?.contentTitles || []}
                />
              </Col>
            </Row>
            {selectedThread ? (
                <MessageList
                    thread={selectedThread}
                    onAddMessage={handleAddMessage}
                    onExecuteContextChat={handleExecuteContextChat}
                    onSuccess={handleSuccess}
                />
            ) : (
                <p className="text-center mt-3">
                  {t("please_select_thread")} 
                </p>
            )}
          </Col>
        </Row>
      </Container>
  );
};

export default Dialog;
