import React from "react";
import { Button } from "reactstrap";
import "./Button.css"
export const PrimaryButton = ({ type, color, onClick, icon: Icon, label, disabled }) => {
  return (
    <Button
      type={type}
      color={color}
      onClick={onClick}
      className="primary-btn"
      disabled={disabled}
    >
       {Icon && <Icon className="icon" />} {label}
    </Button>
  );
};

export const SecondaryButton = ({ type, color, onClick, icon: Icon, label, disabled }) => {
    return (
      <Button
        type={type}
        color={color}
        onClick={onClick}
        className="secondary-btn"
        disabled={disabled}
      >
         {Icon && <Icon className="icon" />} {label}
      </Button>
    );
  };
  export const CancelButton = ({ type, color, onClick, icon: Icon, label, disabled }) => {
    return (
      <Button
        type={type}
        color={color}
        onClick={onClick}
        className="cancel-btn"
        disabled={disabled}
      >
         {Icon && <Icon className="icon" />} {label}
      </Button>
    );
  };
  export const OptionButton = ({ type, borderColor, textColor, iconColor, onClick, icon: Icon, label, disabled, active }) => {
    return (
      <Button
        type={type}
        style={{ borderColor: borderColor, color: textColor, borderWidth: '1px', borderStyle: 'solid' }}
        onClick={onClick}
        disabled={disabled}
        className={`option-btn  ${active ? 'active-btn' : ''}`}

      >
        {Icon && <Icon className="icon" style={{ color: iconColor }} />} {label}
      </Button>
    );
};



export default PrimaryButton;
