const REACT_APP_AZURE_CLIENT_ID = process.env.REACT_APP_AZURE_CLIENT_ID;
const REACT_APP_AZURE_TENANT_ID = process.env.REACT_APP_AZURE_TENANT_ID;
const REACT_APP_AZURE_REDIRECT_URI = process.env.REACT_APP_AZURE_REDIRECT_URI;

export const msalConfig = {
    auth: {
        clientId: REACT_APP_AZURE_CLIENT_ID, // Erstatt med ditt klient-ID
        authority: `https://login.microsoftonline.com/${REACT_APP_AZURE_TENANT_ID}/v2.0`, // Erstatt med ditt tenant-ID
        redirectUri: REACT_APP_AZURE_REDIRECT_URI, // Erstatt med din redirect URI
    },
    cache: {
        cacheLocation: "localStorage", // Mulige verdier er "localStorage" eller "sessionStorage"
        storeAuthStateInCookie: false, // Settes til true hvis du har problemer på IE11 eller Edge
    }
};

export const loginRequest = {
    scopes: [
        "openid",
        "profile",
        "email",
        "api://73d8e8f6-73f5-4cc8-90d6-669c9f35b0f0/user_impersonation",
    ],
    forceRefresh: true
};
