import axios from 'axios';

const API_URL = `${process.env.REACT_APP_FAVN_API_BASE_URI}/v1/data/thread`;

const fetchData = async (url, accessToken, method = 'get', data = null) => {
    try {
        const contextId = localStorage.getItem('selectedContext');
        const headers = {
            Authorization: `Bearer ${accessToken}`,
            'Context-Id': contextId || '',  // Legger til Context-Id fra localStorage
        };
        const config = {
            method,
            url,
            headers,
            data,
        };
        const response = await axios(config);
        return response.data;
    } catch (error) {
        console.error(`Error fetching data from ${url}:`, error);
        throw error;
    }
};

export const fetchThreads = async (accessToken) => {
    return await fetchData(API_URL, accessToken);
};

export const addThread = async (thread, accessToken) => {
    return await fetchData(API_URL, accessToken, 'post', thread);
};

export const addMessage = async (threadId, message, accessToken) => {
    const url = `${API_URL}/${threadId}/messages`;
    return await fetchData(url, accessToken, 'post', message);
};

export const editThread = async (threadId, updatedThread, accessToken) => {
    const url = `${API_URL}/${threadId}`;
    return await fetchData(url, accessToken, 'patch', updatedThread);
};

export const deleteThread = async (threadId, accessToken) => {
    const url = `${API_URL}/${threadId}`;
    return await fetchData(url, accessToken, 'delete');
};
