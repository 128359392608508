import React from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { useTranslation } from "react-i18next";
import { useTheme } from "../theme/theme";
import { CancelButton, SecondaryButton } from "./buttons/Button";

const LogoutModal = ({ isOpen, toggle, onConfirm }) => {
  const { t } = useTranslation();
  const { theme } = useTheme();

  return (
    <Modal isOpen={isOpen} toggle={toggle} centered>
      <ModalHeader
        toggle={toggle}
        style={{
          backgroundColor: "var(--primary-dark)",
        }}
      >
        {t("confirm_logout")}
      </ModalHeader>
      <ModalBody
        style={{
          backgroundColor:
            theme === "light"
              ? "var(--background-color)"
              : "var(--background-color-dark)",
          color: theme === "light" ? "#000000" : "#ffffff",
        }}
      >
        {t("logout_confirmation")}
      </ModalBody>
      <ModalFooter
        style={{
          backgroundColor:
            theme === "light"
              ? "var(--background-color)"
              : "var(--background-color-dark)",
          color: theme === "light" ? "#000000" : "#ffffff",
        }}
      >
        <SecondaryButton onClick={onConfirm} label={t("yes")} />
        <CancelButton onClick={toggle} label={t("cancel")} />
      </ModalFooter>
    </Modal>
  );
};

export default LogoutModal;
