import React from 'react';
import { FormGroup, Input, Label } from 'reactstrap';
import './ModelListItem.css';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../../theme/theme';

const ModelListItem = ({ model, onToggle, isSelected }) => {
    const { t } = useTranslation();

    const { theme } = useTheme();

    return (
        <FormGroup check className="model-list-item mb-2"  style={{ 
            backgroundColor: theme === 'light' ? 'var(--background-color)' : 'var(--background-color-darker)',
            color: theme === 'light' ? '#000000' : '#ffffff',
          
          }}>
            <Label check className="d-flex align-items-center justify-content-between w-100">
                <span className="model-name">{model.name}</span>
                <Input
                    type="switch"
                    id={`model-switch-${model.id}`}
                    checked={isSelected}
                    onChange={() => onToggle(model.id)}
                    className="model-switch"
                    aria-label={t('toggle_model', { name: model.name })}
                />
            </Label>
        </FormGroup>
    );
};

export default ModelListItem;
