import React, { useState } from "react";
import { ListGroupItem } from "reactstrap";
import { FaUser, FaRobot, FaTrash, FaCopy, FaCheck } from "react-icons/fa";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import rehypeSanitize from "rehype-sanitize";
import "./MessageListItem.css";
import { useTheme } from "../../theme/theme";
import {DeleteModal} from "../deleteModal/DeleteModal";

const MessageListItem = ({ message, onDelete }) => {
    const { theme } = useTheme();
    const isUser = message.role === "user";
    const [copied, setCopied] = useState(false);
    const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);

    const handleCopy = () => {
        navigator.clipboard.writeText(message.content)
            .then(() => {
                setCopied(true);
                setTimeout(() => setCopied(false), 2000);
            })
            .catch(err => console.error("Failed to copy text: ", err));
    };

    const toggleDeleteModal = () => {
        setDeleteModalOpen(!isDeleteModalOpen);
    };

    const confirmDelete = () => {
        onDelete();
        toggleDeleteModal();
    };

    return (
        <ListGroupItem
            style={{
                backgroundColor: theme !== 'light' ? 'var(--background-color-dark)' : '',
                color: theme === 'light' ? '#000000' : '#ffffff',
            }}
            className={`${isUser ? 'message-item message-user' : 'message-item message-assistant'}`}
        >
            <div className="d-flex justify-content-between">
                <div className="d-flex">
                    <div className="message-role me-1">
                        {isUser ? (
                            <FaUser />
                        ) : (
                            <FaRobot />
                        )}
                    </div>
                    <div className="text-capitalize fw-bold">{message.role}</div>
                </div>
                <div className="message-icons">
                    {copied ? (
                        <FaCheck className="copied-icon" />
                    ) : (
                        <FaCopy onClick={handleCopy} />
                    )}
                    <FaTrash onClick={toggleDeleteModal} />
                </div>
            </div>
            <div>
                <ReactMarkdown
                    rehypePlugins={[rehypeRaw, rehypeSanitize]}
                    children={message.content}
                />
            </div>
            <DeleteModal
                isOpen={isDeleteModalOpen}
                toggle={toggleDeleteModal}
                handleDelete={confirmDelete}
            />
        </ListGroupItem>
    );
};

export default MessageListItem;
