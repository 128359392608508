import { useState, useCallback } from 'react';
import { classifyCategory } from '../api/classifyApi';
import { useAuth } from '../auth/authContext';

const useClassify = () => {
    const [category, setCategory] = useState(null);
    const [loading, setLoading] = useState(false);
    const { getAccessToken, logout, isInitialized } = useAuth();

    const handle401Error = useCallback(async () => {
        if (!isInitialized) return;
        try {
            await getAccessToken();
        } catch (silentError) {
            console.error('Silent token acquisition failed:', silentError);
            await logout();
        }
    }, [getAccessToken, logout, isInitialized]);

    const classifyCategoryData = useCallback(async (categoryData) => {
        if (!isInitialized) return;
        setLoading(true);
        try {
            const token = await getAccessToken();
            if (!token) {
                await handle401Error();
                return;
            }
            const result = await classifyCategory(categoryData, token);
            setCategory(result);
            return result;
        } catch (error) {
            if (error.response && error.response.status === 401) {
                await handle401Error();
            } else {
                console.error('Failed to classify category:', error);
            }
            throw error;
        } finally {
            setLoading(false);
        }
    }, [getAccessToken, handle401Error, isInitialized]);

    return {
        category,
        loading,
        classifyCategoryData,
    };
};

export default useClassify;
